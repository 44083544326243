export const url = ($strapi) => (path) => {
  if (path.startsWith("http://") || path.startsWith("https://"))
    return path;
  const { options: { url = "" } = {} } = $strapi
  const u = (url.substr(-1) === '/') ? url.slice(0, -1) : url;
  const p = (path.substr(0,1) === '/') ? path.slice(1) : path;
  return  `${u}/${p}`
};


export const metaTags = ($strapi) => (seo) => {
  const tags = [];

  if (seo.metaTitle) {
    tags.push(
      {
        property: "og:title",
        content: seo.metaTitle,
      },
      {
        name: "twitter:title",
        content: seo.metaTitle,
      }
    );
  }
  if (seo.metaDescription) {
    tags.push(
      {
        name: "description",
        content: seo.metaDescription,
      },
      {
        property: "og:description",
        content: seo.metaDescription,
      },
      {
        name: "twitter:description",
        content: seo.metaDescription,
      }
    );
  }
  if (seo.shareImage) {
    const imageUrl = url($strapi)(seo.shareImage.url);
    tags.push(
      {
        name: "image",
        content: imageUrl,
      },
      {
        property: "og:image",
        content: imageUrl,
      },
      {
        name: "twitter:image",
        content: imageUrl,
      }
    );
  }
  if (seo.article) {
    tags.push({
      property: "og:type",
      content: "article",
    });
  }
  if (seo.metaKeywords) {
    tags.push({
      name: "keywords",
      hid: "keywords",
      content: seo.metaKeywords,
    });
  }
  if (seo.metaRobots) {
    tags.push({
      name: "robots",
      hid: "robots",
      content: seo.metaRobots,
    });
  }
  tags.push({ name: "twitter:card", content: "summary_large_image" });

  return tags;
}

export default { url, metaTags };