// TODO: DRY with Kolu

const TokenKey = 'warexAuth';
const RefreshTokenKey = 'warexAuthRefresh';

const mockStorage = {}

export function getToken () {
  if (typeof(localStorage) === "undefined") {
    return mockStorage[TokenKey];
  }
  let token;
  try {
    token = JSON.parse(localStorage.getItem(TokenKey));
  } catch (err) {
    console.error(err);
    token = null;
  }
  return token;
}

export function setToken (token) {
  if (typeof(localStorage) === "undefined") {
    mockStorage[TokenKey] = token
    return 
  }
  return localStorage.setItem(TokenKey, JSON.stringify(token));
}

export function removeToken () {
  if (typeof(localStorage) === "undefined") {
    delete mockStorage[TokenKey] 
    return 
  }
  return localStorage.removeItem(TokenKey);
}

export function getRefreshToken () {
  if (typeof(localStorage) === "undefined") {
    return mockStorage[RefreshTokenKey];
  }

  let rToken;
  try {
    rToken = JSON.parse(localStorage.getItem(RefreshTokenKey));
  } catch (err) {
    console.error(err);
    rToken = null;
  }
  return rToken;
}

export function setRefreshToken (token) {
  if (typeof(localStorage) === "undefined") {
    mockStorage[RefreshTokenKey] = token;
    return
  }

  return localStorage.setItem(RefreshTokenKey, JSON.stringify(token));
}

export function removeRefreshToken () {
  if (typeof(localStorage) === "undefined") {
    delete mockStorage[RefreshTokenKey]; 
    return
  }

  return localStorage.removeItem(RefreshTokenKey);
}

export { TokenKey, RefreshTokenKey };
