import Vue from 'vue';

const setPath = (path) => {
  const _hsq = window._hsq = window._hsq || [];
  _hsq.push(['setPath', path]);
}
const pageView = (path) => {
  const _hsq = window._hsq = window._hsq || [];
  _hsq.push(['setPath', path]); _hsq.push(['trackPageView']);
}
Vue.prototype.$hsq = { setPath, pageView };

export default async (context) => {
  const {app, route} = context;

  setPath(route.fullPath);
  app.$normalizeConfig("portalId")
    .then((pid) => {
      if(!pid) {
        console.error("portal id not available")
        return
      }
      const script = document.createElement("script");
      script.src = `//js.hs-scripts.com/${pid}.js`;
      script.id = 'hs-script-loader'
      script.async = ''
      script.defer = ''
      document.body.appendChild(script);
    });
}
