<template>
  <span>
    <span
      class="inline-block"
      :key="index"
      v-for="(item, index) in text.split('\n')"
    >{{item}}</span>
  </span>
</template>

<script>
export default {
  props: {
    text: { type: String, required: true }
  }
}
</script>