<template>
  <div class="bg-white text-default flex items-center ">
    <div class="flex-grow">
      <input
        class="heap-search-global-menu border-none appearance-none outline-none w-full"
        type="text"
        :placeholder="$t('warehouse.letsEnterAddress')"
        clearable
        v-model="searchAddress"
        @keypress.enter="goSearch"
      />
    </div>
    <div class="bg-secondary rounded-full text-white w-6 h-6 m-1 flex-shrink-0 flex items-center justify-center cursor-pointer" @click="goSearch">
      <span class="material-icons text-base">search</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      searchAddress: ''
    };
  },
  mounted() {
    if(/warehouses\-search\-query/.test(this.$route.name)) {
      this.searchAddress = this.$route.params.query;
    } else {
      if(this.$route.query.address) {
        this.searchAddress = this.$route.query.address;
      }
    }
  },
  watch: {
    '$route.params.query': function(v) {
      this.searchAddress = v;
    },
    '$route.query.address': function(v) {
      this.searchAddress = v;
    }
  },
  methods: {
    goSearch () {
      const { searchAddress } = this;
      if (searchAddress !== '') {
        let query = Object.assign({}, this.$route.query) || {};
        query = { ...query, address: searchAddress, distance:50 };
        this.$router.push({ path: this.localePath('/warehouses/search/map/'), query: {...query} });
      } else {
        if(this.$route.query.address) {
          this.searchAddress = this.$route.query.address;
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
input::placeholder
  @apply text-xs
</style>
