import { http, url, structureAddressObj, destructureAddressObj, normalize } from '@/api/warex/base';

export const registerUser = (user) => {
  const userObj = destructureAddressObj(user);
  let registerPath = user.sso ? '/sso/register' : '/register_user';
  return http.post(url(registerPath), userObj)
    .then((resp) => ( structureAddressObj(resp.data) ));
};

// TODO: what is the difference between login and authenticate?
export const login = (username, password) => {
  return http.post(url('/login/'), { username, password });
}

export const logout = (refreshToken) => {
  return http.post(url('/logout'), { refreshToken });
}

export const requestResetPassword = (email) => {
  return vanilla().post(url('/reset-password'), email);
}

export const resetPassword = (newPassword, resetPasswordToken) => {
  return vanilla().put(url('/reset-password'), { newPassword, resetPasswordToken });
}

export default {
  registerUser,
  resetPassword,
  requestResetPassword,
  login,
  logout
};
