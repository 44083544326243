import Vue from 'vue';
import directive from './directive';
import Middleware from '../middleware'
import featureToggleMiddleware from './middleware'

Middleware["featureToggle"] = featureToggleMiddleware;

import * as FeatureToggleStore from './store';

export default async ({ store, isClient, params, query, req }) => {
  const { prefix = "toggle_", namespace = "featureToggle" } = JSON.parse(`{}`);

  store.registerModule(namespace, FeatureToggleStore, { preserveState: Boolean(store.state[namespace]) });
  // We need to call nuxtServerInit with the req so that in SSR mode we will be
  // able to determine the absolute URL to the flipper service
  // TODO: do this more like the way we do it in Hibiscus to properly deal with full static environments
  process.client
    ? store.commit("featureToggle/BASE_URL", window.location.origin)
    : store.dispatch("featureToggle/nuxtServerInit", { req });

  Vue.component('feature-toggle', () => import('./component.vue'));
  Vue.directive("toggle", directive(store));

  if (prefix !== undefined) {
    // set the key prefix that will be used to detect toggles from the query
    // string and route params
    store.commit("featureToggle/KEY_PREFIX", prefix);
  }

  await store.dispatch("featureToggle/load")
    .then(() => (
      // params are the values provided to the vue router by our vue code
      store.dispatch("featureToggle/loadFromParams", params)
        // query are the Query Parameters provided in the URL
        .then(() => store.dispatch("featureToggle/loadFromParams", query))
    ))
    .catch(err => console.error("error getting feature toggles from toggle service", err));
};
