// feature-toggle directive is a basic shorthand for applying toggle behavior
// to any element. Please note that the directives are reactive to updates in
// the store, but the implementation is hacky. You should use the
// <feature-toggle> component instead whenever possible.
//
// @example: show the password reset form if the "password-reset" toggle is on
//   <reset-password-request-form v-toggle="'password-reset'" ...
//
// @example: show the dropoff item if the "dropoff" toggle is on
//   <dropoff-item v-toggle:dropoff ...
export default (store) => {
  const handleEl = (el, { value, arg }) => {
    const name = value || arg;
    if (!name) {
      throw new Error("toggle directive requries a name");
    }
    if (store.getters["featureToggle/isOff"](name)) {
      // TODO: use the vnode instead of the el
      el.style.display = "none";
    }
    // https://forum.vuejs.org/t/custom-directive-update-on-vuex-state-change/37453
    const unwatch = store.watch((state, getters) => getters["featureToggle/isOn"](name), (on) => {
      // TODO: set the display style to the one it originally had
      el.style.display = on
        ? 'block'
        : 'none';
    });

    el.__toggle_unwatch__ = unwatch;
  };

  return {
    bind: handleEl,
    update: handleEl,
    unbind (el) {
      const u = el.__toggle_unwatch__;
      if (typeof u === "function") {
        u();
      }
    }
  };
};
