<template>
  <footer class="bg-gray-500 text-white pb-8 pt-16">
    <div class="max-w-6xl w-7/8 mx-auto">
      <div class="mb-10 w-full lg:mb-0 lg:w-auto"><img class="lg:h-12 lg:w-auto" width="186" src="~assets/img/logotype_warex_negative.png" alt="" srcset="~assets/img/logotype_warex_negative@2x.png 2x, ~assets/img/logotype_warex_negative@2x.png 3x"></div>
      <div class="my-4 lg:my-12">
        <searchByProvince />
        <hr class="border-white mt-12">
      </div>
      <div class="flex flex-wrap justify-between items-start box-content">
        <ul class="text-sm flex flex-wrap w-full">
          <li class="mt-6 w-1/2 sm:w-auto sm:mr-6 lg:hidden"><nuxt-link :to="localePath('/lp/ec-buffer/')">{{$t('nav.ecBufferShort')}}</nuxt-link></li>
          <li class="mt-6 w-1/2 sm:w-auto sm:mr-6 lg:hidden"><nuxt-link :to="localePath('faq')">{{$t('nav.faq')}}</nuxt-link></li>
          <li class="mt-6 w-1/2 sm:w-auto sm:mr-6 lg:hidden"><nuxt-link :to="localePath('how-to-use')">{{$t('nav.howToUse')}}</nuxt-link></li>
          <li class="mt-6 w-1/2 sm:w-auto sm:mr-6 lg:hidden"><nuxt-link :to="localePath('fortheowners')">{{$t('nav.forTheOwners')}}</nuxt-link></li>
          <li class="hidden lg:block mt-0 mr-6" name="case-studies">
            {{ $t('nav.whatIs') }}
            <ul class="text-xs flex flex-col">
              <li class="mt-2"><nuxt-link :to="localePath('/lp/ec-buffer/')">{{ $t('nav.ecBufferShort') }}</nuxt-link></li>
              <li class="mt-2"><nuxt-link :to="localePath('faq')">{{ $t('nav.faq') }}</nuxt-link></li>
              <li class="mt-2"><nuxt-link :to="localePath('how-to-use')">{{ $t('nav.howToUse') }}</nuxt-link></li>
            </ul>
          </li>
          <feature-toggle tag="li" class="hidden lg:block mt-0 mr-6" name="case-studies">
            {{ $t('nav.casestudyContents') }}
            <ul class="text-xs flex flex-col">
              <li class="mt-2"><nuxt-link :to="localePath('case-study')">{{ $t('nav.casestudy') }}</nuxt-link></li>
              <li class="mt-2"><nuxt-link :to="localePath('contents')">{{ $t('nav.contents') }}</nuxt-link></li>
            </ul>
          </feature-toggle>
          <feature-toggle tag="li" class="mt-6 w-1/2 sm:w-auto sm:mr-6 lg:hidden" name="case-studies"><nuxt-link :to="localePath('case-study')">{{ $t('nav.casestudy') }}</nuxt-link></feature-toggle>
          <feature-toggle tag="li" class="mt-6 w-1/2 sm:w-auto sm:mr-6 lg:hidden" name="case-studies"><nuxt-link :to="localePath('contents')">{{ $t('nav.contents') }}</nuxt-link></feature-toggle>
          <feature-toggle tag="li" class="mt-6 w-1/2 sm:w-auto sm:mr-6 lg:mt-0" name="news">
            <nuxt-link :to="localePath('news')">{{ $t('nav.news') }}</nuxt-link>
          </feature-toggle>
          <feature-toggle tag="li" class="mt-6 w-1/2 sm:w-auto sm:mr-6 lg:mt-0" name="seminars">
            <nuxt-link :to="localePath('seminar')">{{ $t('nav.seminar') }}</nuxt-link>
          </feature-toggle>
          <feature-toggle tag="li" class="mt-6 w-1/2 sm:w-auto sm:mr-6 lg:mt-0" name="whitepapers">
            <nuxt-link :to="localePath('whitepaper')">{{ $t('nav.document') }}</nuxt-link>
          </feature-toggle>
          <li class="mt-6 w-1/2 sm:w-auto sm:mr-6 lg:mt-0"><nuxt-link :to="localePath('contact')">{{$t('nav.inquiries')}}</nuxt-link></li>
          <li class="mt-6 w-1/2 sm:w-auto sm:mr-6 lg:mt-0"><nuxt-link :to="localePath('/warehouses/search/area')">{{$t('nav.area')}}</nuxt-link></li>
          <li class="w-full flex-grow lg:w-auto mt-12 lg:-mt-3">
            <div class="sm:flex justify-start items-center lg:justify-end">
              <button v-if="anonymous" class="contact-link sm:mr-4"
                @click="openModalDialog">{{$t('nav.signUp')}}
              </button>
              <feature-toggle name="search" tag="a" class="document-link"
                href="/warehouses/search/map/東京都/?distance=50">{{$t('nav.searchWarehouse')}}
              </feature-toggle>
            </div>
            <div class="mt-4 text-right hidden lg:block"><nuxt-link :to="localePath('fortheowners')">{{$t('nav.forTheOwnersFooter')}}</nuxt-link></div>
          </li>
        </ul>
        <ul class="sm:flex flex-wrap md:justify-center w-full text-xs mt-12">
          <li class="mt-4 md:mt-0"><nuxt-link :to="localePath('termsofuse')">{{$t('nav.termsOfUse')}}</nuxt-link></li>
          <li class="mt-4 md:mt-0 sm:pl-4 sm:ml-4 sm:border-l-2"><nuxt-link :to="localePath('privacypolicies')">{{$t('nav.privacyPolicy')}}</nuxt-link></li>
          <li class="mt-4 md:mt-0 sm:pl-4 sm:ml-4 sm:border-l-2"><a href="https://www.gaussy.com/" target="_blank">{{$t('nav.company')}}</a></li>
        </ul>
      </div>
      <div class="mt-12 text-xs md:text-center md:text-sm">Copyright {{ new Date().getFullYear() }} Gaussy Inc. All rights reserved.</div>
    </div>
  </footer>
</template>

<style lang="sass" scoped>
.contact-link, .document-link, .signup-link
  @apply flex justify-center items-center mt-6 py-3 px-8 rounded-full text-center w-48 whitespace-no-wrap
  @screen sm
    @apply inline-flex px-4 mt-0 w-full
    max-width: 12rem
  @screen lg
    max-width: 6rem
.contact-link
  @apply text-white bg-orange
.document-link
  @apply bg-secondary text-white
</style>

<script>
import modalMixin from '@/components/mixins/modalHandler';
import searchByProvince from '@/layouts/base/searchByProvince';
export default {
  mixins: [ modalMixin ],
  components: {
    searchByProvince
  },
  computed: {
    anonymous () {
      return this.$store.getters["isAnonymous"]
    },
    canLogin () {
      return this.$store.getters["canLogin"]
    },
    canUse () {
      return this.$store.getters["canUse"]
    }
  },
};
</script>
