import api from '@/api/warex/dynConfig';

export const state = () => ({ items: [], notFound: {} })

export const getters = {
    warexDeliveryMargin (_state, getters) {
        const config = _state.items.find(dc => dc.key === 'warex_delivery_margin');
        return config?.value || 0.12;
    }
}

// TODO: Move margin to serverside (currently this action isn't even being called and default in getter is returned)
export const actions = {
    load ({ commit }) {
        return api.getAll();
    }
}

