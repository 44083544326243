// @see https://github.com/lewyuburi/nuxt-validate/issues/1
import Vue from 'vue'
import { ValidationObserver, ValidationProvider, configure, localize, extend } from 'vee-validate'
import PhoneNumber from 'awesome-phonenumber'

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
configure({
  inject: false
})

import * as rules from 'vee-validate/dist/rules'
for (let rule in rules) {
  extend(rule, rules[rule])
}

const phoneNumber = {
  validate (value) {
    return new Promise(resolve => {
      let phone = new PhoneNumber(value, 'JP')
      resolve({ valid: phone.isValid() })
    })
  }
}
extend('phoneNumber', phoneNumber)

import ja from 'vee-validate/dist/locale/ja.json'
import en from 'vee-validate/dist/locale/en.json'
ja.messages['phoneNumber'] = (field) => `${field}は有効な電話番号ではありません`
en.messages['phoneNumber'] = (field) => `${field} is not a valid phone number`

export default function({ app }) {
  localize('ja', ja)
  localize('en', en)

  // Localizing the app when user refresh or access a localized link
  localize(app.i18n.loadedLanguages[0])
  
  // beforeLanguageSwitch called right before setting a new locale
  app.i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {
    console.log(newLocale);
    localize(newLocale)
  }
}