import path from 'path';
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import axios from 'axios'

export default async ({ app }) => {
  const { router: { options: { base = "/" } = {} } = {} } = app;

  // Retrieve a configuration value either from an environment variable or from a
  // Maui configuration server
  async function getConfig () {
    if (process.env.GMAP_API_KEY) {
      return process.env.GMAP_API_KEY;
    }
    let value;
    const url = path.join(base, "_maui", "config", "GMAP_API_KEY");
    await axios.get(url)
      .then(({ data }) => {
        value = data;
      })
      .catch((e) => {
        console.error(`failed to get gmap api key`)
        console.error(e)

      });
    return value;
  };

  Vue.use(VueGoogleMaps, {
    load: {
      key: await getConfig (),
      libraries: 'places',
      region: 'JP',
      language: 'ja'
    }
  });
};
