<i18n>
{
  "ja": {
    "firstTimeUserConsumer": "はじめての方は、こちらから。\n無料登録して、倉庫を探しましょう。",
    "firstTimeUserProvider": "はじめての方は、こちらから。\n倉庫登録をすると利用者から案件が届きます。",
    "firstTimeUserStep2": "ユーザー作成までもう少しです。登録を進めましょう。",
    "register": "登録する",
    "login": "ログイン",
    "googleBtn": "アカウントで無料登録",
    "or": "または",
    "termsOfUse": {
      "owner": "WareXの",
      "link": "利用規約",
      "text": "に同意します。"
    },
    "email": "Eメール",
    "emailPlaceholder": "メールアドレス",
    "password": "パスワード",
    "passwordPlaceholder": "パスワード：半角英数字で8文字以上",
    "companyEmail": "メールアドレス",
    "companyName": "会社名",
    "firstName": "名",
    "lastName": "姓",
    "phone": "電話番号",
    "optionConsumer": "倉庫利用者",
    "optionProvider": "倉庫提供者",
    "enterEmail": "有効なメールアドレスを入力して下さい。",
    "enterPassword": "パスワードを入力して下さい。",
    "enterCompanyName": "会社名を入力して下さい。",
    "enterLastName": "姓を入力して下さい。",
    "enterFirstName": "名を入力して下さい。",
    "enterPhone": "有効な電話番号を入力して下さい。",
    "errorRegistration": "利用登録ができませんでした。",
    "hasAccount?": "WareXのアカウントはお持ちですか？",
    "doLogin": "ログインする",
    "welcomeToWareX": "ようこそWareXへ！",
    "emailSent": "ご登録いただいたメールアドレスに\n本登録のためのURLをお送りしました。",
    "checkYourEmail": "メールをご確認ください。",
    "submitted_title":"無料登録完了",
    "password_strength": "大文字・小文字・数字を含む8文字以上のパスワードを設定してください。",
    "duplicateEmail": "お使いのメールアドレスはすでに登録されています。"
  }
}
</i18n>

<style lang="sass" scoped>
@screen lg
  form
    @apply text-sm
    input, select
      @apply text-xs
</style>

<template>
  <div :class="`modal fixed z-50 w-screen h-screen top-0 left-0 flex items-center justify-center ${(showRegistrationModal ? 'pointer-events-auto': 'opacity-0 pointer-events-none')}`">
    <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-75" @click="close"></div>

    <div class="modal-container w-full z-50 py-12 max-h-screen overflow-y-scroll scrolling-touch">
      <div class="modal-content relative pt-6 pb-8 px-6 text-center bg-white rounded shadow-lg w-11/12 md:max-w-md mx-auto">
        <div class="modal-close absolute top-0 right-0 -mt-8 z-10 cursor-pointer" @click="close" >
          <img class="h-6 w-6" src="~/assets/img/cross_white.svg"/>
        </div>
        <header v-if="!isSubmitted" class="text-center">
          <img class="inline-block" src="~/assets/img/logo_banner_color.svg" />
        </header>
        <div v-if="isSubmitted" class="text-center py-12">
          <div>
            <img src="~/assets/img/souko_circle.svg" class="inline-block">
          </div>
          <h2 class="mt-16 text-2xl font-bold">{{$t('welcomeToWareX')}}</h2>
          <p class="mt-8"><flex-span :text="$t('emailSent')" />{{$t('checkYourEmail')}}</p>
        </div>
        <form v-else class="bg-white pt-px">
          <div> <!-- registration form -->
            <p class="my-8 lg:my-4">
              <flex-span :text="$t(!user.sso ? (user.type === 'provider' ? 'firstTimeUserProvider' : 'firstTimeUserConsumer') : 'firstTimeUserStep2')" />
            </p>
            <p v-if="errorMessages.registration" class="mb-4 text-red-500 text-xs italic">{{$t('errorRegistration')}}</p>

            <div>
              <div v-if="!user.sso" class="mb-1">
                <a :href="getGoogleLoginUrl()" class="block bg-white text-gray-600 rounded-sm leading-none py-3 px-6 border border-gray-400 w-full shadow">
                  <img src="~/assets/img/icon_google.svg" class="inline-block pr-6"><span class="font-semibold">Google</span>{{$t('googleBtn')}}
                </a>
                <div class="w-full text-center text-xs pt-1">{{$t('or')}}</div>
              </div>
              <div class="mb-1">
                <input id="email" type="text"
                  v-model="user.email"
                  :placeholder="$t('companyEmail')"
                  :class="inputClass(errorMessages.email)"
                  :disabled="user.sso"
                >
                <p v-if="errorMessages.email" class="error-massage">{{ errorMessages.email }}</p>
              </div>
              <div class="mb-1">
                <input id="companyName" type="text"
                  v-model="user.companyName"
                  :placeholder="$t('companyName')"
                  :class="inputClass(errorMessages.companyName)"
                >
                <p v-if="errorMessages.companyName" class="error-massage">{{$t('enterCompanyName')}}</p>
              </div>

              <div class="mb-1 grid grid-cols-2 gap-x-4">
                <div class="">
                  <input id="lastName" type="text"
                    v-model="user.lastName"
                    :placeholder="$t('lastName')"
                    :class="inputClass(errorMessages.lastName)"
                    :disabled="user.sso"
                  >
                  <p v-if="errorMessages.lastName" class="error-massage">{{$t('enterLastName')}}</p>
                </div>

                <div class="">
                  <input id="firstName" type="text"
                    v-model="user.firstName"
                    :placeholder="$t('firstName')"
                    :class="inputClass(errorMessages.firstName)"
                    :disabled="user.sso"
                  >
                  <p v-if="errorMessages.firstName" class="error-massage">{{$t('enterFirstName')}}</p>
                </div>
              </div>
            </div>

            <div>
              <div v-if="!user.sso" class="mb-1">
                <input id="password" type="password"
                  v-model="user.password"
                  :placeholder="$t('passwordPlaceholder')"
                  autocomplete="on"
                  :class="inputClass(errorMessages.password)"
                />
                <p v-if="errorMessages.password" class="error-massage">{{ errorMessages.password }}</p>
              </div>
              <div class="mb-1">
                <input id="phone" type="text"
                  v-model="user.phone"
                  :placeholder="$t('phone')"
                  :class="inputClass(errorMessages.phone)"
                >
                <p v-if="errorMessages.phone" class="error-massage">{{$t('enterPhone')}}</p>
              </div>
            </div>

            <div class="tos text-sm block text-center mt-4 mb-8 lg:mb-4 lg:mt-2">
              <label>
                <input class="hidden" type="checkbox" v-model="accepted">
                <div class="flex items-center justify-center">
                  <span class="checkbox material-icons mr-3 text-secondary">{{accepted ? 'check_box' : 'check_box_outline_blank' }}</span>
                  {{$t('termsOfUse.owner')}}
                  <a class="align-baseline text-sm text-secondary font-bold underline" :href="localePath('termsofuse')" target="_blank" @click="$store.dispatch('hideRegistrationModal')">
                    {{$t('termsOfUse.link')}}
                  </a>
                  {{$t('termsOfUse.text')}}
                </div>
              </label>
            </div>

            <div class="my-8 lg:my-4">
              <button
                type="button"
                class="heap-registration-button btn-register block mb-2 w-full rounded-full py-4 px-4 focus:outline-none font-bold text-white"
                @click="register"
                :disabled="!accepted"
                :class="accepted ? 'bg-secondary' : 'bg-disabled'"
              >
                {{$t('register')}}
              </button>
            </div>

            <div class="text-center text-sm">
              <span>{{$t('hasAccount?')}}</span>
              <a class="inline-block link-login align-baseline text-sm text-secondary font-bold underline" href="/workspace/login">
                {{$t('doLogin')}}
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="sass">
.input-text
  @apply appearance-none w-full bg-white border border-gray-200 py-3 px-4 pr-8 mb-3 rounded leading-tight
.input-text-disabled
  @apply appearance-none w-full bg-gray-100 border border-gray-200 py-3 px-4 pr-8 mb-3 rounded leading-tight text-gray-500 shadow-none
.error-massage
  @apply text-error text-xs text-left -mt-2
</style>

<script>
import { isEmpty, capitalize } from '@/components/utils/string';
import FlexSpan from '@/components/flexSpan';
import { url } from '@/api/warex/base';
import PhoneNumber from 'awesome-phonenumber'

export default {
  components: { FlexSpan },
  data() {
    return {
      user: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        companyName: '',
        phone: '',
        type: this.$route.query.provider === null ? 'provider' : 'consumer',
        sso: false
      },
      errorMessages: {
        login: null,
        registration: null,
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        companyName: null,
        phone: null
      },
      accepted: false,
      isValidated: true,
      isSubmitted: false,
      showForgotPassword: false,
      showLoading: false
    };
  },
  computed: {
    showRegistrationModal() {
      return this.$store.getters.showRegistrationModal;
    },
    isDone() {
      if(!this.$store.getters.isAnonymous) {
        return true;
      }
      return this.isSubmitted;
    }
  },
  mounted() {
    const query = this.$route.query;
    if(query.registration!==null) {
      if(query.email && query.given_name && query.family_name) {
        this.user.email = query.email;
        this.user.firstName = query.given_name;
        this.user.lastName = query.family_name;
        this.user.sso = true;
      }
    }
  },
  methods: {
    inputClass(error) {
      return `${isEmpty(error) ? '' : 'border-red-500'} appearance-none border rounded w-full py-2 px-3 text-grey-200 mb-3 leading-tight focus:outline-none`;
    },
    close() {
      this.$store.dispatch('hideRegistrationModal');
      this.reset();
    },
    validate() {
      this.clearMessages();
      this.isValidated = true;
      if (this.showForgotPassword) {
        this.checkRequiredField('email', this.user.email);
      }
      else {  // registration form
        this.checkEmailPattern('email', this.user.email);
        this.checkRequiredField('companyName', this.user.companyName);
        this.checkRequiredField('lastName', this.user.lastName);
        this.checkRequiredField('firstName', this.user.firstName);
        if (!this.user.sso) {
          this.checkPasswordStrength('password', this.user.password);
        }
        this.checkPhoneField(this.user.phone);
      }
      return this.isValidated;
    },
    checkPhoneField(value) {
      const valid = new PhoneNumber(value, 'JP').isValid();
      if (! valid) {
        this.setErrorByField('phone');
      }
    },
    checkRequiredField(name, value) {
      // We also need to trim value to deny " "
      if (isEmpty(value.trim())) {
        this.setErrorByField(name);
      }
    },
    setErrorByField(name) {
      this.errorMessages[name] = this.$t(`enter${capitalize(name)}`);
      this.isValidated = false;
    },
    checkEmailPattern (name, value) {
      // refs: https://www.w3resource.com/javascript/form/email-validation.php
      const regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$");
      if (!regex.test(value)) {
        this.errorMessages[name] = this.$t('enterEmail');
        this.isValidated = false;
      };
    },
    checkPasswordStrength (name, value) {
      // Password should be more than 8 characters long and include at least one lower case, uppercase and an alphanumeric character
      const regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
      if (!regex.test(value)) {
        this.errorMessages[name] = this.$t('password_strength');
        this.isValidated = false;
      };
    },
    clearMessages() {
      Object.keys(this.errorMessages).forEach((key,index) => {
        this.errorMessages[key] = '';
      });
    },
    reset() {
      this.clearMessages();
      // clear inputs
      Object.keys(this.user).forEach((key,index) => {
        this.user[key] = null;
      });
      this.isSubmitted = false;
    },
    goBack() {
      this.reset();
    },
    async register() {
      if (this.validate()) {
        this.showLoading = true;
        const result = await this.$store.dispatch('registerUser', this.user);
        if (result.isError) {
          const { error: { response: { status } = {} } = {} } = result;
          if (status === 409) {
            this.errorMessages.registration = this.$t('duplicateEmail');
          } else if (status === 406) {
            const fieldErrors = result?.message?.error || [];

            fieldErrors.forEach(fieldError => {
              const fieldName = fieldError.field;
              fieldName.length && this.setErrorByField(fieldName.toLowerCase());
            });
            this.errorMessages.registration = this.$t('errorRegistration');
          } else {
            this.errorMessages.registration = this.$t('errorRegistration');
            this.$bugsnag.notify(result.error, (event) => {
              event.context = 'registration failure';
              event.addMetadata('response', { response: result.error.response });
            });
          }
        }
        else {
          const trackPageview = `${this.$route.fullPath}/thankyou/`
          this.$gtm.push({
            trackPageview,
            title: this.$t('submitted_title'),
            event: 'loadready',
          });

          if (this.user.sso) {
            window.location.href = this.getGoogleLoginUrl();
          } else {
            this.isSubmitted = true; // show the submission success dialog
          }
        }
        this.showLoading = false;
      }
    },
    getGoogleLoginUrl() {
      return '/api/v2/sso/login';
    }
  }
};
</script>
