<i18n>
{
  "ja": {
    "accept": "同意する",
    "deny": "同意しない"
  },
  "en": {
    "accept": "Agree",
    "deny": "Reject"
  }

}
</i18n>

<template>
  <div v-if="isOpen" class="cookie fixed z-50 inset-x-0 bottom-0">
    <div class="bg-gray-600 text-white text-xs p-4 lg:flex justify-center items-center">
      <p class="text-justify mb-6 lg:mb-0">
        <template v-if="$i18n.locale==='ja'">
          当ウェブサイトでは利用者の利便性向上のためにCookieを使用しています。詳細情報は
          <nuxt-link class="underline" :to="localePath('privacypolicies')">{{$t('nav.privacyPolicy')}}</nuxt-link>
          に記載されています。［{{$t('accept')}}］をクリックすると、Cookieの使用に同意したことになります。
        </template>
        <template v-else>
        This website uses cookies to improve user experience. More
        information can be found in our
        <nuxt-link class="underline" :to="localePath('privacypolicies')">{{$t('nav.privacyPolicy')}}</nuxt-link>.
        By clicking "Agree", you consent to the use of cookies.
        </template>
      </p>
      <button
        @click="accept"
        class="lg:ml-10 bg-secondary leading-none whitespace-no-wrap py-2 px-8 rounded-full"
      >{{$t('accept')}}</button>
      <button
        @click="deny"
        class="ml-3 bg-gray-800 leading-none whitespace-no-wrap py-2 px-8 rounded-full"
      >{{$t('deny')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieMessage",
  computed: {
    isOpen() {
      return this.$store.state.gdpr.show
    },
  },
  methods: {
    accept() {
      this.$store.dispatch("acceptGDPR", { $ga: this.$ga, $router: this.$router });
    },
    deny() {
      this.$store.dispatch("rejectGDPR");
    }
  }
};
</script>
