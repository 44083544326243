import axios from 'axios'
import urlJoin from 'url-join';
import reqURL from 'requrl';
import applyConverters from 'axios-case-converter';

export default ({ app, req }, inject) => {
  const { router: { options: { base = "/" } = {} } = {} } = app;
  const baseURL = process.env.API_PUBLIC_URL || (req && reqURL(req)) || base

  const isValidGeocodeValue = (value) => {
    const number = parseFloat(value);
    if (isNaN(number) || number === 0) return false;

    return number >= -90 && number <= 90 || number >= -180 && number <= 180;
  }

  /* getLatLng - generated by shell script -------------
    #!/bin/bash

    # Replace your API key here
    API_KEY="GOOGLE_API_KEY"

    # List of all prefectures 都道府県名
    prefectures=("北海道" "青森県" "岩手県" "宮城県" "秋田県" "山形県" "福島県" "茨城県" "栃木県" "群馬県" "埼玉県" "千葉県" "東京都" "神奈川県" "新潟県" "富山県" "石川県" "福井県" "山梨県" "長野県" "岐阜県" "静岡県" "愛知県" "三重県" "滋賀県" "京都府" "大阪府" "兵庫県" "奈良県" "和歌山県" "鳥取県" "島根県" "岡山県" "広島県" "山口県" "徳島県" "香川県" "愛媛県" "高知県" "福岡県" "佐賀県" "長崎県" "熊本県" "大分県" "宮崎県" "鹿児島県" "沖縄県")

    # get geocode info for each prefecture and output them as JSON
    echo "["

    for prefecture in "${prefectures[@]}"; do
        # URL encode the prefecture name
        encoded_prefecture=$(echo "$prefecture" | sed 's/ /%20/g')

        # Geocoding API
        response=$(curl -s "https://maps.googleapis.com/maps/api/geocode/json?address=${encoded_prefecture}&key=${API_KEY}")

        # Extract latitude / longitude
        latitude=$(echo "$response" | jq -r '.results[0].geometry.location.lat')
        longitude=$(echo "$response" | jq -r '.results[0].geometry.location.lng')

        echo "{\"prefecture\": \"$prefecture\", \"latitude\": $latitude, \"longitude\": $longitude},"

        # Waiting time to comply with API limits
        sleep 1
    done

    echo "]"
  */
  const getLatLng = (prefecture) => {
    return [
      {"prefecture": "北海道", "latitude": 43.2203266, "longitude": 142.8634737},
      {"prefecture": "青森県", "latitude": 40.7657077, "longitude": 140.9175879},
      {"prefecture": "岩手県", "latitude": 39.5832989, "longitude": 141.2534574},
      {"prefecture": "宮城県", "latitude": 38.630612, "longitude": 141.1193048},
      {"prefecture": "秋田県", "latitude": 40.1376293, "longitude": 140.334341},
      {"prefecture": "山形県", "latitude": 38.5370564, "longitude": 140.1435198},
      {"prefecture": "福島県", "latitude": 37.3834373, "longitude": 140.1832516},
      {"prefecture": "茨城県", "latitude": 36.2193571, "longitude": 140.1832516},
      {"prefecture": "栃木県", "latitude": 36.6714739, "longitude": 139.8547266},
      {"prefecture": "群馬県", "latitude": 36.5605388, "longitude": 138.8799972},
      {"prefecture": "埼玉県", "latitude": 35.9962513, "longitude": 139.4466005},
      {"prefecture": "千葉県", "latitude": 35.3354155, "longitude": 140.1832516},
      {"prefecture": "東京都", "latitude": 35.6761919, "longitude": 139.6503106},
      {"prefecture": "神奈川県", "latitude": 35.4913535, "longitude": 139.284143},
      {"prefecture": "新潟県", "latitude": 37.5178386, "longitude": 138.9269794},
      {"prefecture": "富山県", "latitude": 36.6958266, "longitude": 137.2137071},
      {"prefecture": "石川県", "latitude": 36.3260317, "longitude": 136.5289653},
      {"prefecture": "福井県", "latitude": 35.896227, "longitude": 136.2111579},
      {"prefecture": "山梨県", "latitude": 35.6635113, "longitude": 138.6388879},
      {"prefecture": "長野県", "latitude": 36.1543941, "longitude": 137.9218204},
      {"prefecture": "岐阜県", "latitude": 35.7437491, "longitude": 136.9805103},
      {"prefecture": "静岡県", "latitude": 35.0929397, "longitude": 138.3190276},
      {"prefecture": "愛知県", "latitude": 35.0182505, "longitude": 137.2923893},
      {"prefecture": "三重県", "latitude": 33.8143901, "longitude": 136.0487047},
      {"prefecture": "滋賀県", "latitude": 35.3292014, "longitude": 136.0563212},
      {"prefecture": "京都府", "latitude": 35.1566609, "longitude": 135.5251982},
      {"prefecture": "大阪府", "latitude": 34.6413315, "longitude": 135.5629394},
      {"prefecture": "兵庫県", "latitude": 34.8579518, "longitude": 134.5453787},
      {"prefecture": "奈良県", "latitude": 34.2975528, "longitude": 135.8279734},
      {"prefecture": "和歌山県", "latitude": 33.9480914, "longitude": 135.3745358},
      {"prefecture": "鳥取県", "latitude": 35.3573161, "longitude": 133.4066618},
      {"prefecture": "島根県", "latitude": 35.1244094, "longitude": 132.6293446},
      {"prefecture": "岡山県", "latitude": 34.8963407, "longitude": 133.6375314},
      {"prefecture": "広島県", "latitude": 34.8823408, "longitude": 133.0194897},
      {"prefecture": "山口県", "latitude": 34.2796769, "longitude": 131.5212742},
      {"prefecture": "徳島県", "latitude": 33.9419655, "longitude": 134.3236557},
      {"prefecture": "香川県", "latitude": 34.2225915, "longitude": 134.0199152},
      {"prefecture": "愛媛県", "latitude": 33.6025306, "longitude": 132.7857583},
      {"prefecture": "高知県", "latitude": 33.5481246, "longitude": 133.2521507},
      {"prefecture": "福岡県", "latitude": 33.56625590000001, "longitude": 130.715857},
      {"prefecture": "佐賀県", "latitude": 33.3078371, "longitude": 130.2271243},
      {"prefecture": "長崎県", "latitude": 33.2488525, "longitude": 129.6930912},
      {"prefecture": "熊本県", "latitude": 32.8594427, "longitude": 130.7969149},
      {"prefecture": "大分県", "latitude": 33.1589299, "longitude": 131.3611121},
      {"prefecture": "宮崎県", "latitude": 32.6036022, "longitude": 131.441251},
      {"prefecture": "鹿児島県", "latitude": 31.3911958, "longitude": 130.8778586},
      {"prefecture": "沖縄県", "latitude": 26.1201911, "longitude": 127.7025012},
    ].find(item => item.prefecture === prefecture);
  }

  const search = (searchParams, userLocale) => {
    let params = {};
    const { prefecture } = searchParams;
    if (prefecture) {
      const prefObj = getLatLng(prefecture);
      if (prefObj) {
        params.latitude = prefObj.latitude;
        params.longitude = prefObj.longitude;
      }
      else { // default to tokyo
        params.latitude = 35.6761919;
        params.longitude = 139.6503106;
      }
      params.km = 50;
    }
    else {
      params = searchParams;
    }
    const locale = userLocale || app.i18n.locale
    console.debug('sending request to /api/v2/public-warehouses/map');
    return axios.get(urlJoin(baseURL, "api/v2", "/public-warehouses/map"), { params: {...params} })
      .then(({data}) => {
        // Pick the warehouse with proper coordinates
        // AND Temporary hack to exclude private warehouses
        const result = data.filter(wh => isValidGeocodeValue(wh.lat) && isValidGeocodeValue(wh.lng) && !wh.private);
        return result;
      })
      .catch(err => {
        console.error('Server Error---', err, err.response, err.header);
        app.$bugsnag.notify(err);
        return { isError: true, message: err };
      })
  }

  const areaSearch = (query) => {
    console.debug('sending request to /api/v2/public-warehouses/area');
    return axios.get(urlJoin(baseURL, "api/v2", "/public-warehouses/area"), { params: { q: query, private: false } })
      .then((data) => {
        return data;
      })
      .catch(err => {
        console.error('Server Error---', err, err.response, err.header);
        app.$bugsnag.notify(err);
        return { isError: true };
      })
  }

  const getAreas = () => {
    return axios.get(urlJoin(baseURL, "api/v2", "/public-areas"))
      .catch(err => {
        console.error('Server Error---', err, err.response, err.header);
        app.$bugsnag.notify(err);
        return { isError: true };
      })
  }

  const warehouse = (id, userLocale) => {
    const locale = userLocale || app.i18n.locale
    // NOTE: only this entry point returns case converted data
    const axiosWithConverter = applyConverters(axios.create());

    const url = urlJoin(baseURL, "api/v2/public-warehouses/warehouse", id.toString());
    console.debug('sending request to ', url)
    return axiosWithConverter.get(url)
      .then(({data}) => {
        return data;
      })
      .catch(e => {
        console.error("get warehouse failed", e)
        app.$bugsnag.notify(e);
        return { isError: true };
      })
  }

  inject("warex", { search, areaSearch, getAreas, warehouse, baseURL })
}