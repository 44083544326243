import { debounce } from 'vue-debounce'

export default {
  watch: {
    '$route.query.registration'(val) {
      val === null && this.onShowRegistrationModal()
    }
  },
  methods: {
    openModalDialog: debounce(() => {
      const { query } = $nuxt.$route
      if (query.registration !== null) {
        $nuxt.$router.push({ query: { ...query, registration: null, consumer: null } })
      } else {
        $nuxt.$store.dispatch('hideRegistrationModal')
      }
    }, 60),
    onShowRegistrationModal: debounce(() => {
      $nuxt.$store.dispatch('showRegistrationModal')
    }, 150)
  }
};
