<i18n>
{
  "ja": {
    "title" : "ご利用できる全国の倉庫"
  },
  "en": {
    "title" : "Available warehouses in Japan"
  }
}
</i18n>

<template>
  <nav class="seo-links">
    <div class="text-sm text-white mb-4 mt-8">{{$t('title')}}</div>
    <div class="text-xs -mt-3">
      <span v-for="(prefecture, prefectureName, index) in prefectures" :key="index" class="link-container w-auto mt-5 md:my-1 inline-flex items-center">
        <nuxt-link :to="{path:`/warehouses/search/area/${prefecture}`}">{{prefecture}}</nuxt-link>
        <span v-if="prefectureName !== 'okinawa'" class="inline-block h-3 mx-3 border-l border-white"></span>
      </span>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    prefectures () {
      let prefs = [];
      Object.keys(this.$t('areas')).forEach((key) => {
        prefs = prefs.concat(Object.values(this.$t(`${key}-prefectures`)));
      });
      return prefs;
    }
  }
}
</script>
