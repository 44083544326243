export const isEmpty = (str) => {
  return !str || str.length == 0;
};

export const isValid = (str) => {
  return str && typeof str === 'string' && str !== 'undefined'
}

export const capitalize = (str) => {
  if(!str) return str;
  return str.substring(0,1).toUpperCase() + str.substring(1);
}
