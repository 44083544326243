// @see https://medium.com/@tircomnicu_77850/nuxt-js-and-gdpr-creating-a-custom-cookie-component-e7957eedcfd
// @see https://ja.nuxtjs.org/faq/google-analytics/
// @see https://github.com/MatteoGabriele/vue-analytics/blob/master/docs/opt-out.md

import axios from 'axios';
import Bugsnag from '@bugsnag/js';

export default ({ app, store, route }) => {
  const enforceGDPR = () => {
    const getGDPR = localStorage.getItem("GDPR:accepted2");

    if (typeof getGDPR === "undefined" || getGDPR === null) {
      store.commit("showGDPR");
    } else {
      store.commit("hideGDPR");
      if (app.$ga) {
        if (getGDPR === "true") {
          app.$ga.enable()
          app.$ga.page(route.path);
        } else {
          app.$ga.disable()
        }
      }
    }
  }

  axios.get("/whereami/")
    .then(( { data } ) => {
      if (data.is_eu === "true") {
        return enforceGDPR()
      }
      if (app.$ga) {
        app.$ga.enable()
        app.$ga.page(route.path);
      }
      store.commit("hideGDPR");
    })
    .catch((err) => {
      console.error(err);
      enforceGDPR();
      Bugsnag.notify(err);
    })
};
