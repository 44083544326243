export const DEBUG    = 0
export const INFO     = 1
export const WARN     = 2
export const ERROR    = 3
export const CRITICAL = 4
export const DEFAULT  = WARN

function nextId(state) {
  if(state.list.length === 0) {
    return 0
  }
  return state.list[state.list.length - 1].id + 1
}

export const state = () => ({
  list: []
})

function add(state, m) {
  if(!m.level) {
    m.level = DEFAULT
  }
  m.id = nextId(state)
  state.list.push(m)
}

export const mutations = {
  ADD(state, m) {
    add(state, m)
  },

  ERROR(state, m) {
    m.level = ERROR
    add(state, m)
  },

  WARN(state, m) {
    m.level = WARN
    add(state, m)
  },

  INFO(state, m) {
    m.level = INFO
    add(state, m)
  },

  DEBUG(state, m) {
    m.level = DEBUG
    add(state, m)
  },

  CRITICAL(state, m) {
    m.level = CRITICAL
    add(state, m)
  },

  DISMISS(state, { id }) {
    state.list = state.list.filter((m) => m.id !== id)
  }
}

export const getters = {
  messages: (state) => {
    return state.list
  },

  errors: (state) => {
    return state.list.filter((m) => (m.level === ERROR || m.level == CRITICAL))
  },

  byScope: (state) => (scope) => {
    return state.list.filter((m) => (m.scope === scope))
  }
}
