export const vueI18n = {"silentFallbackWarn":true,"fallbackLocale":"ja"}
export const vueI18nLoader = true
export const locales = [{"code":"ja","iso":"ja-JP","file":"ja.js","name":"日本語"}]
export const defaultLocale = "ja"
export const defaultDirection = "ltr"
export const routesNameSeparator = "___"
export const defaultLocaleRouteNameSuffix = "default"
export const strategy = "prefix_except_default"
export const lazy = true
export const langDir = "/hibiscus/lang"
export const rootRedirect = null
export const detectBrowserLanguage = {"useCookie":true,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"alwaysRedirect":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":false}
export const differentDomains = false
export const seo = false
export const baseUrl = ""
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const skipSettingLocaleOnNavigate = false
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const IS_UNIVERSAL_MODE = true
export const MODULE_NAME = "nuxt-i18n"
export const LOCALE_CODE_KEY = "code"
export const LOCALE_ISO_KEY = "iso"
export const LOCALE_DIR_KEY = "dir"
export const LOCALE_DOMAIN_KEY = "domain"
export const LOCALE_FILE_KEY = "file"
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = "nuxtI18n"
export const localeCodes = ["ja"]
export const trailingSlash = true

export const ASYNC_LOCALES = {
  'ja.js': () => import('../../lang/ja.js' /* webpackChunkName: "lang-ja.js" */)
}
