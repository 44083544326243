import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3340d892 = () => interopDefault(import('../pages/case-study/index.vue' /* webpackChunkName: "pages/case-study/index" */))
const _95c54266 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _3489e52a = () => interopDefault(import('../pages/contactError.vue' /* webpackChunkName: "pages/contactError" */))
const _c88401b4 = () => interopDefault(import('../pages/contactSuccess.vue' /* webpackChunkName: "pages/contactSuccess" */))
const _51c88000 = () => interopDefault(import('../pages/contents/index.vue' /* webpackChunkName: "pages/contents/index" */))
const _4d33e8be = () => interopDefault(import('../pages/document.vue' /* webpackChunkName: "pages/document" */))
const _6820ad43 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _57721ccb = () => interopDefault(import('../pages/fortheowners.vue' /* webpackChunkName: "pages/fortheowners" */))
const _5a0cc696 = () => interopDefault(import('../pages/how-to-use.vue' /* webpackChunkName: "pages/how-to-use" */))
const _23ef98f4 = () => interopDefault(import('../pages/inquiry.vue' /* webpackChunkName: "pages/inquiry" */))
const _0f898c0e = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _1e8b72f6 = () => interopDefault(import('../pages/privacypolicies.vue' /* webpackChunkName: "pages/privacypolicies" */))
const _9568c5be = () => interopDefault(import('../pages/seminar/index.vue' /* webpackChunkName: "pages/seminar/index" */))
const _57ced10c = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _011e6af8 = () => interopDefault(import('../pages/user-contact/index.vue' /* webpackChunkName: "pages/user-contact/index" */))
const _68dd342e = () => interopDefault(import('../pages/whitepaper/index.vue' /* webpackChunkName: "pages/whitepaper/index" */))
const _7adc5f27 = () => interopDefault(import('../pages/lp/ec-buffer.vue' /* webpackChunkName: "pages/lp/ec-buffer" */))
const _446b66b3 = () => interopDefault(import('../pages/user-contact/thankyou.vue' /* webpackChunkName: "pages/user-contact/thankyou" */))
const _6f176efb = () => interopDefault(import('../pages/warehouses/search/area/index.vue' /* webpackChunkName: "pages/warehouses/search/area/index" */))
const _016a91c6 = () => interopDefault(import('../pages/warehouses/search/map/_prefecture.vue' /* webpackChunkName: "pages/warehouses/search/map/_prefecture" */))
const _08ba9c5a = () => interopDefault(import('../pages/warehouses/search/area/_prefecture/_area.vue' /* webpackChunkName: "pages/warehouses/search/area/_prefecture/_area" */))
const _b2cf6da4 = () => interopDefault(import('../pages/campaign/_slug.vue' /* webpackChunkName: "pages/campaign/_slug" */))
const _318a374a = () => interopDefault(import('../pages/case-study/_slug.vue' /* webpackChunkName: "pages/case-study/_slug" */))
const _5535c290 = () => interopDefault(import('../pages/contents/_slug.vue' /* webpackChunkName: "pages/contents/_slug" */))
const _c92d30dc = () => interopDefault(import('../pages/form/_id.vue' /* webpackChunkName: "pages/form/_id" */))
const _12f6ce9e = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _98d6084e = () => interopDefault(import('../pages/seminar/_slug.vue' /* webpackChunkName: "pages/seminar/_slug" */))
const _666c189e = () => interopDefault(import('../pages/warehouses/_id.vue' /* webpackChunkName: "pages/warehouses/_id" */))
const _6c4a76be = () => interopDefault(import('../pages/whitepaper/_slug.vue' /* webpackChunkName: "pages/whitepaper/_slug" */))
const _d7e68882 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/case-study/",
    component: _3340d892,
    pathToRegexpOptions: {"strict":true},
    name: "case-study___ja"
  }, {
    path: "/contact/",
    component: _95c54266,
    pathToRegexpOptions: {"strict":true},
    name: "contact___ja"
  }, {
    path: "/contactError/",
    component: _3489e52a,
    pathToRegexpOptions: {"strict":true},
    name: "contactError___ja"
  }, {
    path: "/contactSuccess/",
    component: _c88401b4,
    pathToRegexpOptions: {"strict":true},
    name: "contactSuccess___ja"
  }, {
    path: "/contents/",
    component: _51c88000,
    pathToRegexpOptions: {"strict":true},
    name: "contents___ja"
  }, {
    path: "/document/",
    component: _4d33e8be,
    pathToRegexpOptions: {"strict":true},
    name: "document___ja"
  }, {
    path: "/faq/",
    component: _6820ad43,
    pathToRegexpOptions: {"strict":true},
    name: "faq___ja"
  }, {
    path: "/fortheowners/",
    component: _57721ccb,
    pathToRegexpOptions: {"strict":true},
    name: "fortheowners___ja"
  }, {
    path: "/how-to-use/",
    component: _5a0cc696,
    pathToRegexpOptions: {"strict":true},
    name: "how-to-use___ja"
  }, {
    path: "/inquiry/",
    component: _23ef98f4,
    pathToRegexpOptions: {"strict":true},
    name: "inquiry___ja"
  }, {
    path: "/news/",
    component: _0f898c0e,
    pathToRegexpOptions: {"strict":true},
    name: "news___ja"
  }, {
    path: "/privacypolicies/",
    component: _1e8b72f6,
    pathToRegexpOptions: {"strict":true},
    name: "privacypolicies___ja"
  }, {
    path: "/seminar/",
    component: _9568c5be,
    pathToRegexpOptions: {"strict":true},
    name: "seminar___ja"
  }, {
    path: "/termsofuse/",
    component: _57ced10c,
    pathToRegexpOptions: {"strict":true},
    name: "termsofuse___ja"
  }, {
    path: "/user-contact/",
    component: _011e6af8,
    pathToRegexpOptions: {"strict":true},
    name: "user-contact___ja"
  }, {
    path: "/whitepaper/",
    component: _68dd342e,
    pathToRegexpOptions: {"strict":true},
    name: "whitepaper___ja"
  }, {
    path: "/lp/ec-buffer/",
    component: _7adc5f27,
    pathToRegexpOptions: {"strict":true},
    name: "lp-ec-buffer___ja"
  }, {
    path: "/user-contact/thankyou/",
    component: _446b66b3,
    pathToRegexpOptions: {"strict":true},
    name: "user-contact-thankyou___ja"
  }, {
    path: "/warehouses/search/area/",
    component: _6f176efb,
    pathToRegexpOptions: {"strict":true},
    name: "warehouses-search-area___ja"
  }, {
    path: "/warehouses/search/map/:prefecture?/",
    component: _016a91c6,
    pathToRegexpOptions: {"strict":true},
    name: "warehouses-search-map-prefecture___ja"
  }, {
    path: "/warehouses/search/area/:prefecture/:area?/",
    component: _08ba9c5a,
    pathToRegexpOptions: {"strict":true},
    name: "warehouses-search-area-prefecture-area___ja"
  }, {
    path: "/campaign/:slug?/",
    component: _b2cf6da4,
    pathToRegexpOptions: {"strict":true},
    name: "campaign-slug___ja"
  }, {
    path: "/case-study/:slug?/",
    component: _318a374a,
    pathToRegexpOptions: {"strict":true},
    name: "case-study-slug___ja"
  }, {
    path: "/contents/:slug/",
    component: _5535c290,
    pathToRegexpOptions: {"strict":true},
    name: "contents-slug___ja"
  }, {
    path: "/form/:id?/",
    component: _c92d30dc,
    pathToRegexpOptions: {"strict":true},
    name: "form-id___ja"
  }, {
    path: "/news/:slug/",
    component: _12f6ce9e,
    pathToRegexpOptions: {"strict":true},
    name: "news-slug___ja"
  }, {
    path: "/seminar/:slug/",
    component: _98d6084e,
    pathToRegexpOptions: {"strict":true},
    name: "seminar-slug___ja"
  }, {
    path: "/warehouses/:id?/",
    component: _666c189e,
    pathToRegexpOptions: {"strict":true},
    name: "warehouses-id___ja"
  }, {
    path: "/whitepaper/:slug/",
    component: _6c4a76be,
    pathToRegexpOptions: {"strict":true},
    name: "whitepaper-slug___ja"
  }, {
    path: "/",
    component: _d7e68882,
    pathToRegexpOptions: {"strict":true},
    name: "index___ja"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
