<template>
  <div :class="bodyClass">
    <client-only>
      <warex-gdpr />
    </client-only>
    <warex-header ref="header"></warex-header>
    <nuxt />
    <warex-footer></warex-footer>
    <registration-dialog />
  </div>
</template>

<script>
import  WarexHeader from '@/layouts/base/header';
import  WarexFooter from '@/layouts/base/footer';
import WarexGdpr from "@/layouts/base/gdpr";
import RegistrationDialog from '@/components/registrationModalDialog';
import { debounce } from 'vue-debounce';
import { isValid as isValidString } from '@/components/utils/string'

export default {
  components: {
    WarexGdpr,
    WarexHeader,
    WarexFooter,
    RegistrationDialog
  },
  data() {
    return { global: null }
  },
  fetch() {
    this.global = this.$store.getters['cms/global']
  },
  head() {
    const { defaultSeo, siteName } = this.global;
    const fullSeo = {
      ...defaultSeo,
    };
    const globalTitle = isValidString(fullSeo.metaTitle) && fullSeo.metaTitle || ''
    const globalSitename = isValidString(siteName) && siteName || ''
    const titleTemplate = (globalTitle || globalSitename) && `%s｜${globalSitename}${globalTitle && globalSitename ? ' - ' : ''}${globalTitle}`
    return {
      ...(titleTemplate && { titleTemplate }),
      meta: [
        ...(globalSitename && [{ property: "og:site_name", content: globalSitename }] || []),
        { charset: 'utf-8' },
        { 'http-equiv': "x-ua-compatible", content: "ie=edge" },
        { name: "viewport", content: "width=device-width, initial-scale=1, shrink-to-fit=no" },
        { name: "referrer", content: "no-referrer-when-downgrade" },
        { hid: "robots", name: "robots", content: "all" },
        { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
        { rel: 'shortcut icon', type: 'image/x-icon', href: '/favicon.ico' },
        { property: "og:url", content: `${this.$seo.url(this.$route.fullPath)}` }
      ],
      link: [
        {
          rel: 'canonical',
          hid: 'canonical',
          href: `${this.$seo.url(this.$route.path.replace(/^\/en/, ''))}`
        }
      ],
      bodyAttrs: {
        class: this.$store.state.nav.open ? "nav-open" : ""
      }
    }
  },
  watch: {
    '$route.fullPath' : function (v) {
      this.$hsq.pageView(v);
    }
  },
  mounted () {
    this.resizeClientVieport();
    this.onResize = debounce(this.resizeClientVieport, 400);
    window.addEventListener('resize', this.onResize);
  },
  computed: {
    bodyClass() {
      return this.$store.state.showRegistrationModal ? 'modal-active' : '';
    },
  },
  async middleware({ store, route, redirect }) {
    const enPath = route.fullPath.match('/en(\/.*)')
    if (enPath && enPath.length > 0) {
      return redirect(enPath[1] || '/')
    }
  },
  methods: {
    resizeClientVieport () {
      const viewport = document.documentElement;
      this.$store.commit('nav/clientHeight', { height: viewport.clientHeight });
      this.$store.commit('nav/clientWidth', { width: viewport.clientWidth });
      const headerRect = this.$refs.header.$el.getBoundingClientRect();
      const headerHeight = headerRect.bottom - headerRect.top;
      this.$store.commit('nav/headerHeight', { height: headerHeight });
    }
  }
}
</script>
