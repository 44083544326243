import { analyticsMiddleware } from 'vue-analytics'

const MD_MIN_WIDTH = 768;
const LG_MIN_WIDTH = 1024;

export const state = () => ({
  open: false,
  active: {
    top: null,
    page: null,
  },
  links: {
    'why-aukai': [
      { path: 'why-aukai-overview', name: 'overview' },
      { path: 'why-aukai-platform', name: 'platform' },
      { path: 'why-aukai-network',  name: 'network' },
      { path: 'why-aukai-expert',   name: 'expert' },
    ],

    'who-we-help': [
      { path: 'who-we-help-digital-natives', name: 'digital' },
      { path: 'who-we-help-enterprise', name: 'enterprise' },
      { path: 'who-we-help-warehouse-providers', name: 'providers' }
    ],

    'what-we-do': [
      { path: 'what-we-do-ecommerce-fulfillment', name: 'ec' },
      { path: 'what-we-do-retail-distribution', name: 'retail' },
      { path: 'what-we-do-inventory-overflow', name: 'overflow' }
    ],

    'company': [
      { path: 'company-resources', name: 'resources' },
      { path: 'company-contact'  , name: 'contact' }
    ]
  },
  headerHeight: 0,
  subheaderHeight: 0,
  clientHeight: 0,
  clientWidth: 0
})


export const getters = {
  active(state) {
    return {
      top: state.active.top,
      page: state.active.page,
      links: state.links[state.active.top]
    }
  },
  headerHeight: (state) => (state.headerHeight + state.subheaderHeight),
  subheaderHeight: (state) => (state.subheaderHeight),
  clientHeight: (state) => (state.clientHeight),
  clientWidth: (state) => (state.clientWidth),
  isMobile: (_state, getters) => (getters.clientWidth < MD_MIN_WIDTH),
  isDesktop: (_state, getters) => (getters.clientWidth >= LG_MIN_WIDTH)
}

export const mutations = {
  toggle(state) {
    state.open = !state.open
  },

  open(state) {
    state.open = true
  },

  close(state) {
    state.open = false
  },

  activate(state, { top, page }) {
    state.active.top = top
    state.active.page = page
  },

  headerHeight (state, { height }) {
    state.headerHeight = height;
  },

  subheaderHeight (state, { height }) {
    state.subheaderHeight = height;
  },

  clientHeight (state, { height }) {
    state.clientHeight = height;
  },

  clientWidth (state, { width }) {
    state.clientWidth = width;
  }
}

export const actions = {
  toggle({ commit, state }) {
    commit('toggle', { meta: {
      analytics: [
        ['event', {
          eventCategory: 'nav',
          eventAction: 'toggle',
          eventLabel: !state.open ? 'open' : 'close',
          eventValue: 1
        }]
      ]
    }})
  }
}

export const plugins = [
  analyticsMiddleware
]
