export const state = () => ({
  phone: "",
  email: "",
  company: "",
  firstName: "",
  lastName: "",
  message: "",
  warehouseInquiry: null
})

export const getters = {
  messages: (state, getters, rootState, rootGetters) => {
    return rootGetters["messages/byScope"]('contact')
  },
  warehouseInquiry (state) {
    return state.warehouseInquiry;
  }
}

export const mutations = {
  PHONE(state, p) {
    state.phone = p
  },

  EMAIL(state, e) {
    state.email = e
  },

  COMPANY(state, c) {
    state.company = c
  },

  FIRST_NAME(state, f) {
    state.firstName = f
  },

  LAST_NAME(state, l) {
    state.lastName = l
  },

  MESSAGE(state, m) {
    state.message = m
  },
  SET_INQUIRY(state, warehouse) {
    const regionName = warehouse?.address?.regionName || '';
    const muniName = warehouse?.address?.muniName || '';
    state.warehouseInquiry = {
      ...warehouse,
      displayNumber: warehouse?.displayNumber,
      location: `${regionName}${muniName}`
    };
  }
}

export const actions = {
  setInquiry({ commit }, { warehouse }) {
    commit('SET_INQUIRY', warehouse);
  },
  resetInquiry({ commit }) {
    commit('SET_INQUIRY', null);
  },
  async send({ commit, state }, contact) {
    const data = Object.assign({}, state)
    this.$axios.$post('/contact', data)
      .then((resp) => {
        commit('messages/INFO', { scope: 'contact', type: 'SUCCESS', msg: 'msgSent'}, { root: true })
      })
      .catch((error) => {
        if (error.response) {
          let err = {};
          if(error.response.status === 404) {
            err = { type: 'CONFIG_ERROR', msg: 'errorNotFound'}
          } else if(error.response.data.msg) {
              err = error.response.data
          } else if(error.response.data.error) {
            if(typeof(error.response.data.error) === "string") {
              err = { msg: error.response.data.error, type: "REQUEST_ERROR" }
            } else {
              err = { msg: "errorUnrecognized", type: "REQUEST_ERROR" }
              console.warn("unrecognized error", error.response.data.error)
            }

          } else {
            err = { type: 'SERVER_ERROR', msg: 'errorUnknown' }
          }

          commit("messages/ERROR", { ...err, scope: 'contact'}, { root: true })

        } else if (error.request) {
          commit("messages/ERROR", {type: 'SERVER_ERROR', msg: 'errorNoResponse', scope: 'contact'}, { root: true })

        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', {type: 'CLIENT_ERROR', msg: error.message});
        }
        console.log("error config", error.config);
      })
  }
}
