export const state = () => ({
  global: null,
  homepage: null,
  informations: null,
  faqs: null,
  faqCategories: null,
  howToUses: null
});
export const getters = {
  global(state) {
    return state.global || {}
  },
  homepage(state) {
    return state.homepage || {}
  },
  informations(state) {
    return state.informations
  },
  faqs(state) {
    return state.faqs
  },
  faqCategories(state) {
    return state.faqCategories
  },
  howToUses(state) {
    return state.howToUses
  }
};
export const mutations = {
  global(state, payload) {
    state.global = payload
  },
  homepage(state, payload) {
    state.homepage = payload
  },
  informations(state, payload) {
    state.informations = payload
  },
  faqs(state, payload) {
    state.faqs = payload
  },
  faqCategories(state, payload) {
    state.faqCategories = payload
  },
  howToUses(state, payload) {
    state.howToUses = payload
  }
};
const PRIORITIES = { 'high': 1, 'medium': 2, 'low': 3, 'default': 99 }
const getPriorityVal = (order) => {
  const { priority } = order || {};
  return PRIORITIES[priority] || PRIORITIES.default;
}
export const actions = {
  async global({ commit }) {
    const { $strapi, $bugsnag } = this
    let data = null
    try {
      data = await $strapi.find("global")
    } catch(e) {
      $bugsnag.notify(e)
    }
    commit("global", data)
  },
  async homepage({ commit }) {
    const { $strapi, $bugsnag } = this
    let data = null
    try {
      data = await $strapi.find("homepage")
    } catch(e) {
      $bugsnag.notify(e)
    }
    commit("homepage", data)
  },
  async informations({ commit }) {
    const { $strapi, $bugsnag } = this
    let data = null
    try {
      data = await $strapi.find("informations")
    } catch(e) {
      $bugsnag.notify(e)
    }
    commit("informations", data)
  },
  async faqs({ commit }) {
    let items;
    try {
      items = await this.$strapi.$http.$get(`faqs`) || []
      items = items.sort((a, b) => {
        // priority
        const aPriority = getPriorityVal(a.orderby)
        const bPriority = getPriorityVal(b.orderby)
        if (aPriority !== bPriority) {
          return aPriority - bPriority
        }
        return 0
      })
    } catch (e) {
      this.$bugsnag.notify(e)
    }
    commit("faqs", items)
  },
  async faqCategories({ commit }) {
    let items
    try {
      items = await this.$strapi.$http.$get(`faq-categories`) || []
      items = items.sort((a, b) => {
        // priority
        const aPriority = getPriorityVal(a.orderby)
        const bPriority = getPriorityVal(b.orderby)
        if (aPriority !== bPriority) {
          return aPriority - bPriority
        }
        return 0
      })
    } catch(e) {
      this.$bugsnag.notify(e)
    }
    commit("faqCategories", items)
  },
  async howToUses({ commit }) {
    let items
    try {
      items = await this.$strapi.$http.$get(`how-to-uses`) || []
      items = items.sort((a, b) => {
        // priority
        const aPriority = getPriorityVal(a.orderby)
        const bPriority = getPriorityVal(b.orderby)
        if (aPriority !== bPriority) {
          return aPriority - bPriority
        }
        return 0
      })
    } catch(e) {
      this.$bugsnag.notify(e)
    }
    commit("howToUses", items)
  }
};
