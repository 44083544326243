import path from 'path';
import axios from 'axios';
import reqURL from 'requrl';
import urlJoin from 'url-join';

const TEN_MINUTES = 600000;
export const values = {};

export const toID = str => str.replace(/[A-Z]+/g, letter => `_${letter.toLowerCase()}`).toUpperCase();

export default async ({ app, req }, inject) => {
  const { router: { options: { base = "/" } = {} } = {} } = app;
  const baseURL = path.join(base, "_maui", "config");

  //doGet executes the actual query to the server for the config value. It does
  //not cache results. It does not normalize the ID name.
  async function doGet (name) {
    // allows us to behave properly in netlify environments; see netlify.toml
    if (process.env.EXPOSED && process.env.EXPOSED[name]) {
      return process.env.EXPOSED[name];
    }

    const url = urlJoin(reqURL(req), baseURL, name);
    let value
    try {
      const { data } = await axios.get(url)
      value = data
    } catch(e) {
      console.error(`failed to get '${name}' config`)
      console.error(e)
    }
    return value;
  }

  //get normalizes config names, executes the retrieval of the value and caches
  //results. We want the cache to expire values after a short period of time so
  //that we can eventually synchronize with the server if values have been
  //changed there.
  async function get (name) {
    const id = toID(name);
    if (values[id] !== undefined) {
      const item = values[id];
      if (item.expires > Date.now()) {
        return item.value;
      }
    }

    //FIXME: prevent multiple `doGet` operations, for the same id, from running
    //  at the same time.
    const value = await doGet(id);
    const expires = new Date(Date.now() + TEN_MINUTES);
    const item = { value, expires }
    values[id] = item

    return item.value;
  }

  inject('normalizeConfig', get);
};
