import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  window.onNuxtReady(() => {
    new VuexPersistence({
      reducer: (state) => {
        return {
          ...state,
          nav: {
            open: false,
            active: {
              top: null,
              page: null,
            },
            links: {
            }
          },
          warehouses: { },
          featureToggle: { }
        }
      }
    }).plugin(store);
  });
}
