/**
 * feature-toggle/store.js
 *
 * Track the state of feature toggles and manage which ones can be changed via
 * "normal" client code. Toggle names will be kebagized, e.g., password_reset,
 * passwordReset, will become `password-reset` internally. This means that in
 * your components, and other code that sets or retrieves toggle state you can
 * pass toggle names in snake_case, camelCase or kebab-case. If you access
 * state directly use kebab-case.
 *
 * These are all the same thing:
 *   <feature-toggle name="password-reset" ...
 *   <feature-toggle name="passwordReset" ...
 *   <feature-toggle name="password_reset" ...
 *
 * These are all the same thing:
 *   $store.getters["featureToggle/TOGGLE"]("password-reset")
 *   $store.getters["featureToggle/TOGGLE"]("password_reset")
 *   $store.getters["featureToggle/TOGGLE"]("passwordReset")
 *
 * These are not the same thing:
 *   $store.state.featureToggle["password-reset"]
 *   $store.state.featureToggle["password_reset"]
 *   $store.state.featureToggle["passwordReset"]
 * Only the first one will be effective. Use the getters.
 *
 */

import { myfeatures } from './api';

const invalidChars = /[^a-zA-Z0-9:]+/g;

/**
 * Convert text to kebab-case
 * @param {string} str Text to be converted
 * @return {string}
 */
function kebabize (str) {
  return str
    .replace(/[A-Z]/g, match => '-' + match)
    .replace(/([^a-zA-Z])-([A-Z])/g, match => match[0] + match[2])
    .replace(/^-/, '')
    .replace(invalidChars, '-')
    .toLowerCase();
}

const normalize = (toggles, state) => {
  return Object.keys(toggles).reduce((normal, key) => {
    const name = kebabize(key);
    const { enabled } = toggles[key];
    if ([true, "on", "yes", "enable"].includes(enabled)) {
      normal[name] = true;
    } else if ([false, "off", "no", "disable"].includes(enabled)) {
      normal[name] = false;
    } else if (enabled === null) {
      // no value was provided so we want the toggle to be opposite of it's
      // current configuration. if no current configuration exists then we
      // toggle it off. this is typically used to make toggling through the
      // query parameters less verbose
      if (state === undefined || state.toggles === undefined || state.toggles[name] === undefined) {
        normal[name] = false;
      }
      normal[name] = !state.toggles[name];
    } else {
      throw new Error(`unrecognized toggle value (${enabled}) for ${name}`);
    }

    return normal;
  }, {});
};

const canClientToggle = (state, name) => {
  return state.toggles[kebabize('client.toggle')] ||
    state.toggles[kebabize(`client.toggle.${name}`)];
};

export const namespaced = true;

export const state = () => ({
  // baseURL is the absolute URL pointing to the root URL where we can find the
  // flipper service. It needs to be a full
  baseURL: process.env.FLIPPERS_API_PUBLIC_URL ||
    process.env.FLIPPERS_API_URL ||
    (process.client && window.location.origin),
  loaded: false,
  loading: false,
  // set the default and possible toggle values in nuxt.config.js
  toggles: { },
  // keyPrefix is the prefix used in query params to indicate that the
  // parameter is meant to apply to a feature toggle.
  keyPrefix: "toggle_"
});

// mutation names
const LOADED = "LOADED";
const LOADING = "LOADING";
const SET = "SET";
const MERGE = "MERGE";
const ON = "ON";
const OFF = "OFF";
const TOGGLE = "TOGGLE";
const KEY_PREFIX = "KEY_PREFIX";
const BASE_URL = "BASE_URL";

export const mutations = {
  [LOADED]: (state) => {
    state.loaded = true;
    state.loading = false;
  },
  [LOADING]: (state) => {
    state.loaded = false;
    state.loading = true;
  },
  [ON]: (state, key) => {
    const name = kebabize(key);
    if (canClientToggle(state, name)) {
      state.toggles[name] = true;
    } else {
      throw new Error(`toggling ${name} on client is not allowed`);
    }
  },

  [OFF]: (state, key) => {
    const name = kebabize(key);
    if (canClientToggle(state, name)) {
      state.toggles[name] = false;
    } else {
      throw new Error(`toggling ${name} on client is not allowed`);
    }
  },

  [TOGGLE]: (state, key) => {
    const name = kebabize(key);
    if (canClientToggle(state, name)) {
      state.toggles[name] = !state.toggles[name];
    } else {
      throw new Error(`toggling ${name} on client is not allowed`);
    }
  },

  // set the value of toggles
  [SET]: (state, toggles) => {
    state.toggles = { ...normalize(toggles, state) };
  },

  // set the value of toggles
  [MERGE]: (state, toggles) => {
    state.toggles = { ...state.toggles, ...normalize(toggles, state) };
  },

  [KEY_PREFIX]: (state, prefix) => {
    state.keyPrefix = prefix;
  },

  [BASE_URL]: (state, u) => {
    state.baseURL = u
  }
};

export const actions = {
  load ({ commit, rootState, state }) {
    const rootAuth = rootState.auth || {};
    commit(LOADING);
    return myfeatures(state.baseURL, {
      accessToken: rootState.accessToken || rootAuth.accessToken,
      refreshTOken: rootState.refreshToken || rootAuth.refreshToken
    })
      .then((features) => {
        commit(
          SET,
          features.reduce((set, feature) => {
            set[feature.name] = feature;
            return set;
          }, {})
        );
      })
      .finally(() => commit(LOADED));
  },
  loadFromParams: ({ commit, state }, params) => {
    const toggles = {};
    Object.keys(params).forEach((key) => {
      const enabled = params[key];
      if (key.startsWith(state.keyPrefix)) {
        const name = kebabize(key.replace(state.keyPrefix, ""));
        if (!canClientToggle(state, name)) {
          throw new Error(`toggling ${name} on client is not allowed`);
        }
        toggles[name] = { enabled, name };
      }
    });
    commit(MERGE, toggles);
  },

  // nuxtServerInit is manually called by the flipper plugin when in SSR mode.
  // It's necessary because we need to set the base URL for the flipper services
  nuxtServerInit: ({ commit, state }, { req }) => {
    if (!state.baseURL && req) {
      let proto = req.connection.encrypted ? 'https' : 'http';
      proto = req.headers['x-forwarded-proto'] || proto;
      proto = proto.split(/\s*,\s*/)[0];
      const burl = proto + '://' + req.headers.host
      commit(BASE_URL, burl);
    }
  }
};

export const getters = {
  isOn: state => (name) => {
    return state.toggles[kebabize(name)] === true;
  },
  isOff: state => (name) => {
    return state.toggles[kebabize(name)] !== true;
  },
  isEmpty: state => Object.keys(state.toggles).length === 0,
  isLoaded: state => state.loaded
};

export default { namespaced, getters, actions, mutations, state };