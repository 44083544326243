export const url = (baseURL, ...parts) => {
  if (!baseURL) {
    throw new TypeError("baseURL is required");
  }
  if (parts.length < 1) {
    throw new TypeError("url parts required");
  }

  let path = ["flipper", ...parts]
    .filter(p => typeof p === 'string')
    .map(p => p.trim())
    .filter(p => p.length > 0 && p[0] !== '/')
    .map(p => p[0] === '/' ? p.substring(1) : p)
    .map(p => p[p.length - 1] === '/' ? p.substring(0, p.length - 1) : p)
    .join('/');

  if (parts[parts.length - 1] === "/") {
    path = `${path}/`;
  }
  return baseURL[baseURL.length - 1] === "/"
    ? `${baseURL}${path}`
    : `${baseURL}/${path}`;
};

/**
 * myfeatures
 * @param {string} baseURL the absolute URL where the flipper service is
 *  accessible - cannot be a partial URL
 */
export const myfeatures = (baseURL, { accessToken }) => {
  const headers = accessToken
    ? { Authorization: `Bearer ${accessToken}` }
    : {};
  return fetch(url(baseURL, '/'), { headers })
    .then((response) => {
      if (response.ok) {
        return response.json();
      // TODO: else if (response.status === 401)
      //       get new access token with refresh token and replace request
      } else {
        const error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
    });
};

export default { myfeatures };
