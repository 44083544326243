import urlJoin from 'url-join';
import reqURL from 'requrl';

export default async ({ app, req }, inject) => {
  const { router: { options: { base = "/" } = {} } = {} } = app;
  const baseURL = process.env.SEO_BASE_URL || 
  	(req && reqURL(req)) || 
	process.env.API_BASE_URL  || 
	base;
  inject("seo", {
    url: (...parts) => {
      return urlJoin(baseURL, ...parts)
    }
  })
}