<i18n>
{
  "ja": {
    "logotype": "営業倉庫・貸し倉庫・物流倉庫探しはWareX"
  }
}
</i18n>
<template>
  <div class="p-4 pb-0 lg:p-0 relative z-30 header text-black">
    <div
      v-if="showToConsumersOnly"
      id="g_id_onload"
      :data-client_id="$config.GOOGLE_CLIENT_ID"
      data-context="signin"
      data-login_uri="/api/v2/sso/login"
      data-skip_prompt_cookie="gsid"
      data-close_on_tap_outside="false"
      data-prompt_parent_id="g_id_onload"
      style="position: fixed; top: 105px; right: 401px; width: 0; height: 0; z-index: 1001;">
    </div>
    <div class="h-20" v-intersect="onIntersect"></div>
    <div
      class="fixed z-10 top-0 left-0 w-full border-solid lg:border-t-4"
      :class="{
        'lg:bg-white': !isBgTransparentPages,
        'border-secondary': !isShowBanner,
        'border-transparent': isShowBanner
      }"
    >
      <div
        v-if="isShowBanner"
        class="bg-secondary-dark fixed bottom-0 left-0 w-full lg:relative lg:block p-3 -mt-1 pt-4 shaodw-lg"
        :class="[`duration-${bannerAnimTime}`, { 'transition transform translate-y-full lg:translate-y-0 opacity-0': isAnimBanner }]"
        :style="informationData.colorCode ? 'background-color:' + informationData.colorCode : ''"
      >
        <button class="absolute top-1/2 right-0 transform -translate-y-1/2 mx-3" @click="onCloseBanner">
          <img class="hidden lg:block" src="~assets/img/icon_close.svg" alt="close">
          <span class="w-6 h-6 rounded-full bg-white flex justify-center items-center lg:hidden">
            <img src="~assets/img/icon_close2.svg" alt="close" width="12">
          </span>
        </button>
        <div class="md w-7/12 lg:w-full mx-auto text-xs lg:text-sm text-center text-white lg:-mt-1" v-html="$md.render(informationData.information)" />
      </div>
      <div class="header">
        <div class="bg absolute inset-0 bg-white border-solid border-t-4 border-secondary lg:border-0"></div>
        <div class="flex-shrink-0 pl-3 pt-2 pb-1 xl:pl-6 lg:py-0 flex items-center">
          <nuxt-link :to="localePath('index')">
            <img class="logotype w-auto" :alt="$t('logotype')" src="~assets/img/logo_banner_color.svg" />
          </nuxt-link>
        </div>
        <ul class="text-xs xl:text-sm hidden lg:flex flex-row items-center ml-3 xl:ml-5 lg:w-full max-w-2xl">
          <li class="xl:ml-4 btn-menu-wrap">
            <button
              class="btn-menu p-2"
              @click="onShowSubNav(subnav.whatIs)"
            >
              {{ $t('nav.whatIs') }}
            </button>
            <ul
              v-show="isSubnavWhatIs"
              class="absolute rounded-md bg-white text-default shadow-subnav mt-4 py-2 flex flex-col"
            >
              <li>
                <nuxt-link class="py-2 px-4 block" :to="localePath('/lp/ec-buffer/')">{{ $t('nav.ecBuffer') }}</nuxt-link>
              </li>
              <li>
                <nuxt-link class="py-2 px-4 block" :to="localePath('faq')">{{ $t('nav.faq') }}</nuxt-link>
              </li>
              <li>
                <nuxt-link class="py-2 px-4 block" :to="localePath('how-to-use')">{{ $t('nav.howToUse') }}</nuxt-link>
              </li>
            </ul>
          </li>
          <feature-toggle tag="li" class="xl:ml-4 btn-menu-wrap" name="case-studies">
            <button
              class="btn-menu p-2"
              @click="onShowSubNav(subnav.casestudyContents)"
            >
              {{ $t('nav.casestudyContents') }}
            </button>
            <ul
              v-show="isSubnavCasestudyContents"
              class="absolute rounded-md bg-white text-default shadow-subnav mt-4 py-2 flex flex-col"
            >
              <li><nuxt-link class="py-2 px-4 block" :to="localePath('case-study')">{{ $t('nav.casestudy') }}</nuxt-link></li>
              <li><nuxt-link class="py-2 px-4 block" :to="localePath('contents')">{{ $t('nav.contents') }}</nuxt-link></li>
            </ul>
          </feature-toggle>
          <li class="xl:ml-4 btn-menu-wrap">
            <button
              class="btn-menu p-2"
              @click="onShowSubNav(subnav.forms)"
            >
              {{ $t('nav.forms') }}
            </button>
            <ul
              v-show="isSubnavForms"
              class="absolute rounded-md bg-white text-default shadow-subnav mt-4 py-2 flex flex-col"
            >
              <feature-toggle tag="li" name="whitepapers">
                <nuxt-link class="py-2 px-4 block" :to="localePath('whitepaper')">{{ $t('nav.document') }}</nuxt-link>
              </feature-toggle>
              <feature-toggle tag="li" name="seminars">
                <nuxt-link class="py-2 px-4 block" :to="localePath('seminar')">{{ $t('nav.seminar') }}</nuxt-link>
              </feature-toggle>
            </ul>
          </li>
          <li class="xl:ml-4">
            <nuxt-link class="px-2" :to="localePath('fortheowners')">
              {{ $t('nav.forTheOwners') }}
            </nuxt-link>
          </li>
        </ul>
        <ul class="text-xs xl:text-sm hidden lg:flex ml-auto">
          <li class="flex">
            <div class="header-tel relative" @click="toggleDropdown">
              <svg class="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.1632 16.468C19.9313 15.5647 18.4683 14.723 17.6764 14.261L17.6104 14.22C16.7414 13.7272 15.4545 13.8915 14.7395 14.5895C14.1895 15.1336 13.8595 15.4723 13.6835 15.6365C13.0126 15.3286 8.51379 13.1216 5.79692 8.3894C5.99491 8.20463 6.41289 7.79403 7.00686 7.27051C7.75483 6.60328 7.87582 5.29961 7.25985 4.35523C6.34689 2.95918 5.31294 1.69657 4.68597 1.06014C4.30099 0.700858 3.76202 0.505821 3.24504 0.536617C2.76106 0.567412 2.0461 0.813774 1.32013 1.80949C0.0881909 3.50323 -1.17675 6.244 2.0021 10.5348C5.42294 15.1438 9.75673 18.8701 13.3316 20.2558C14.7395 20.7999 16.0154 21.0668 17.1594 21.0668C18.5783 21.0668 19.7993 20.6459 20.7892 19.8042C21.6142 19.1061 22.0212 18.4287 21.9992 17.7922C21.9772 17.2995 21.7022 16.8581 21.1632 16.4578V16.468Z" fill="#00ADA2"/>
              </svg>
              <span class="ml-1 font-bold text-sm">お電話でご相談</span>
              <div v-if="isDropdownOpen" class="phone-dropdown-menu">
                <div class="dropdown-item">
                  <a  href="tel:0120-264-076">
                    <span class="text-xs">倉庫をお探しの方はこちら</span><br/>
                    <span class="phone">0120-264-076</span>
                  </a>
                </div>
                <div class="dropdown-item">
                  <a  href="tel:050-5444-8739">
                    <span class="text-xs">倉庫を登録したい方はこちら</span><br/>
                    <span class="phone">050-5444-8739</span>
                  </a>
                </div>
              </div>
            </div>

            <nuxt-link class="header-mail" to="/contact">
              <span class="text-xs lg:text-sm xl:px-4 lg:px-2">お問い合わせ</span>
            </nuxt-link>
          </li>
          <li class="relative">
            <button class="heap-sign-up-button-global-menu signup-link header-signup text-sm" @click="onRegisterOrLogin">
              <span class="text-sm lg:font-bold">無料登録で利用する</span>
              <span class="text-xs block">またはログイン</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
    <!-- mobile menus-->
    <div class="drawer lg:hidden">
      <div class="fixed top-0 right-0 mt-1 mr-12 z-10 flex">
        <div class="header-tel relative" @click="toggleDropdown">
              <svg class="icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.1632 16.468C19.9313 15.5647 18.4683 14.723 17.6764 14.261L17.6104 14.22C16.7414 13.7272 15.4545 13.8915 14.7395 14.5895C14.1895 15.1336 13.8595 15.4723 13.6835 15.6365C13.0126 15.3286 8.51379 13.1216 5.79692 8.3894C5.99491 8.20463 6.41289 7.79403 7.00686 7.27051C7.75483 6.60328 7.87582 5.29961 7.25985 4.35523C6.34689 2.95918 5.31294 1.69657 4.68597 1.06014C4.30099 0.700858 3.76202 0.505821 3.24504 0.536617C2.76106 0.567412 2.0461 0.813774 1.32013 1.80949C0.0881909 3.50323 -1.17675 6.244 2.0021 10.5348C5.42294 15.1438 9.75673 18.8701 13.3316 20.2558C14.7395 20.7999 16.0154 21.0668 17.1594 21.0668C18.5783 21.0668 19.7993 20.6459 20.7892 19.8042C21.6142 19.1061 22.0212 18.4287 21.9992 17.7922C21.9772 17.2995 21.7022 16.8581 21.1632 16.4578V16.468Z" fill="#00ADA2"/>
              </svg>
              <span class="ml-1 font-bold text-sm">電話相談</span>
              <div v-if="isDropdownOpen" class="phone-dropdown-menu">
                <div class="dropdown-item">
                  <a  href="tel:0120-264-076">
                    <span class="text-xs">倉庫をお探しの方はこちら</span><br/>
                    <span class="phone">0120-264-076</span>
                  </a>
                </div>
                <div class="dropdown-item">
                  <a  href="tel:050-5444-8739">
                    <span class="text-xs">倉庫を登録したい方はこちら</span><br/>
                    <span class="phone">050-5444-8739</span>
                  </a>
                </div>
              </div>
            </div>
        <a class="signup-link dense w-full" @click="onRegisterOrLogin">{{$t('nav.signUp')}}</a>
      </div>
      <label class="fixed top-0 right-0 p-2 z-30">
        <input class="hidden" type="checkbox" v-model="showMenu" />
        <i v-if="!showMenu" class="material-icons text-secondary"><span class="text-3xl">menu</span></i>
        <i v-if="showMenu" class="material-icons text-white"><span class="text-3xl">close</span></i>
      </label>
      <transition name="menu">
        <div v-if="showMenu" class="fixed z-20 inset-0 top-0 p-8 pb-16 bg-secondary text-white overflow-auto max-h-full">
          <div class="mt-12">
            <feature-toggle tag="div" name="search"><search-box class="w-full rounded pl-3" /></feature-toggle>
          </div>
          <ul class="border-t-1 border-white mt-8 pt-3 flex flex-col">
            <li class="py-3 -mx-8 px-8" :class="{ 'bg-secondary-lighter': isSubnavWhatIs }">
              <button
                class="btn-menu flex justify-end items-center w-full"
                @click="onShowSubNav(subnav.whatIs)"
              >
                <span class="mr-auto">{{ $t('nav.whatIs') }}</span>
                <i
                  :class="{ 'rotate-45 -translate-y-1': isSubnavWhatIs, 'rotate-225': !isSubnavWhatIs }"
                  class="inline-block border-b-1 border-r-1 border-white transform mx-1"
                />
              </button>
              <ul v-show="isSubnavWhatIs" class="text-sm">
                <li><nuxt-link @click.native="closeMenu" class="pt-4 px-4 underline block" :to="localePath('/lp/ec-buffer/')">{{$t('nav.ecBuffer')}}</nuxt-link></li>
                <li><nuxt-link @click.native="closeMenu" class="pt-4 px-4 underline block" :to="localePath('faq')">{{ $t('nav.faq') }}</nuxt-link></li>
                <li><nuxt-link @click.native="closeMenu" class="pt-4 px-4 underline block" :to="localePath('how-to-use')">{{ $t('nav.howToUse') }}</nuxt-link></li>
              </ul>
            </li>
            <feature-toggle tag="li" name="case-studies" class="py-3 -mx-8 px-8" :class="{ 'bg-secondary-lighter': isSubnavCasestudyContents }">
              <button
                class="btn-menu flex justify-end items-center w-full"
                @click="onShowSubNav(subnav.casestudyContents)"
              >
                <span class="mr-auto">{{ $t('nav.casestudyContents') }}</span>
                <i
                  :class="{ 'rotate-45 -translate-y-1': isSubnavCasestudyContents, 'rotate-225': !isSubnavCasestudyContents }"
                  class="inline-block border-b-1 border-r-1 border-white transform mx-1"
                />
              </button>
              <ul v-show="isSubnavCasestudyContents" class="text-sm">
                <li><nuxt-link @click.native="closeMenu" class="pt-4 px-4 underline block" :to="localePath('case-study')">{{ $t('nav.casestudy') }}</nuxt-link></li>
                <li><nuxt-link @click.native="closeMenu" class="pt-4 px-4 underline block" :to="localePath('contents')">{{ $t('nav.contents') }}</nuxt-link></li>
              </ul>
            </feature-toggle>
            <feature-toggle tag="li" class="py-3" name="news">
              <nuxt-link @click.native="closeMenu" :to="localePath('news')">{{ $t('nav.news') }}</nuxt-link>
            </feature-toggle>
            <feature-toggle tag="li" class="py-3" name="seminars">
              <nuxt-link @click.native="closeMenu" :to="localePath('seminar')">{{ $t('nav.seminar') }}</nuxt-link>
            </feature-toggle>
            <feature-toggle tag="li" class="py-3" name="whitepapers">
              <nuxt-link @click.native="closeMenu" :to="localePath('whitepaper')">{{ $t('nav.document') }}</nuxt-link>
            </feature-toggle>
            <li class="py-3"><nuxt-link @click.native="closeMenu" :to="localePath('fortheowners')">{{$t('nav.forTheOwners')}}</nuxt-link></li>
            <li class="py-3"><nuxt-link @click.native="closeMenu" :to="localePath('contact')">{{$t('nav.inquiries')}}</nuxt-link></li>
            <li class="mt-4">
              <a class="signup-link w-full rounded-full"
                @click="onRegisterOrLogin">{{$t('nav.signUp')}}
              </a>
            </li>
            <li class="mt-4 py-4" v-if="canUse">
              <hr class="mb-8 border-white opacity-25">
              <a href="/workspace/" @click="closeMenu">{{$t('nav.workspace')}}</a>
            </li>
            <li class="mt-4 py-4 text-center" v-else-if="canLogin">
              <a href="/workspace/" @click="closeMenu">{{$t('nav.login')}}</a>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~assets/css/markdown.sass'
.header
  height: 44px
  @apply w-full flex justify-between border-solid border-b-1 border-gray-200
  @screen lg
    height: 60px

li a
  @apply whitespace-no-wrap
.logotype
  width: 98px
  @apply h-auto
  @screen xl
    width: 120px

.menu-enter-active,
.menu-leave-active
  transition: transform 0.3s
.menu-enter, .menu-leave-to
  transform: translateY(-100%)

.contact-link, .document-link, .signup-link
  @apply inline-flex justify-center items-center h-8 text-center text-xs whitespace-no-wrap px-3
  min-width: 5rem
  &.dense
    @apply h-8
.contact-link
  @apply border border-secondary text-secondary
.signup-link
  @apply text-white bg-orange flex-col leading-tight
  //background-color: #f6af3e
.document-link
  @apply bg-secondary text-white
.drawer
  .contact-link, .document-link, .signup-link
    @apply flex w-auto
  .contact-link
    @apply border border-white text-white
  .document-link
    @apply bg-white text-secondary
  .signup-link
    height: 40px
  .tel
    margin-top: 44px
    @apply fixed top-0 inset-x-0

.bg
  z-index: -1
  transition: opacity 0.5s

.btn-menu
  i
    width: 6px
    height: 6px
.btn-menu-wrap
  ul
    min-width: 12em
.header-signup
  @apply px-2 h-full
  @screen xl
    @apply text-sm px-6
.header-tel
  @apply text-base font-medium flex items-center justify-start whitespace-no-wrap leading-none px-2
  color: #333
  @screen xl
    @apply text-xs pl-4 pr-5
  .text
    font-size: 10px
    transition: margin .5s linear
    @apply flex flex-col items-end ml-2 overflow-hidden font-normal
  .phone
    @apply font-semibold
    letter-spacing: 1.2px

.phone-dropdown-menu
  @apply absolute left-0 border border-gray-300 bg-white text-right rounded-md shadow-md
  top: 100%

  .dropdown-item
    @apply p-4 cursor-pointer border-t-1 border-gray-300

  .dropdown-item:hover
    @apply bg-gray-200

.header-mail
  @apply bg-secondary text-white flex justify-center items-center py-1 font-bold
  @screen xl
    min-width: 138px
</style>

<script>
import { debounce } from 'vue-debounce';
import { getToken } from '@/utils/auth';
import modalMixin from '@/components/mixins/modalHandler';
import SearchBox from '@/components/searchBox';
export default {
  components: {
    SearchBox
  },
  data() {
    return {
      screenWidth: 1280,
      showSubNav: null,
      showMenu: false,
      isIntersecting: true,
      subnav: {
        casestudyContents: 'casestudy-contents',
        forms: 'forms',
        whatIs: 'what-is'
      },
      bannerAnimTime: 300,
      bannerState: 'hide', // show, hide, anim
      informations : null,
      isDropdownOpen: false,
    };
  },
  async fetch() {
    await this.$store.dispatch('cms/informations');
    this.informations = this.$store.getters['cms/informations']
  },
  mixins: [ modalMixin ],
  computed: {
    showToConsumersOnly () {
      // If there is provider in the query url, don't show google sign in
      return ! this.$route.query.hasOwnProperty('provider')
    },
    anonymous () {
      return this.$store.getters["isAnonymous"]
    },
    canLogin () {
      return this.$store.getters["canLogin"]
    },
    canUse () {
      return this.$store.getters["canUse"]
    },
    isFrontPage() {
      return /index/.test(this.$route.name);
    },
    isBgTransparentPages() {
      return (this.isFrontPage || /warehouses-search-area-prefecture-area/.test(this.$route.name));
    },
    isFrontPageAndFirstView() {
      return this.screenWidth >= 1024 && this.isIntersecting && this.isBgTransparentPages;
    },
    isWarehousesSearchPage() {
      return /warehouses\-search/.test(this.$route.name);
    },
    informationData() {
      const { informations } = this
      const informationData = informations && informations.filter((e) =>
        e.isShow && e.allowlist.some((e) => e.title === this.$route.path)
      )
      const isShow = informationData && informationData.length > 0
      if(isShow && !informationData[0].colorCode) {
        const regex = /^#[0-9A-Fa-f]+\s+/;
        const information = informationData[0];
        const colorCode = (() => {
          const prefix = information.information.match(regex);
          return prefix ? prefix[0].replace(/([0-9A-Fa-f]+)\s+/, '$1') : '';
        })();
        informationData[0].information = information.information.replace(regex, '');
        informationData[0].colorCode = colorCode;
      }
      setTimeout(() => {
        isShow && (this.bannerState = 'show')
      }, 500)
      return isShow && informationData[0]
    },
    isShowBanner() {
      return this.informationData && this.bannerState !== 'hide'
    },
    isAnimBanner() {
      return this.bannerState === 'anim'
    },
    isSubnavCasestudyContents() {
      return this.showSubNav === this.subnav.casestudyContents
    },
    isSubnavForms() {
      return this.showSubNav === this.subnav.forms
    },
    isSubnavWhatIs() {
      return this.showSubNav === this.subnav.whatIs
    },
    isSearchPage () {
      return this.$route.path.includes('/search');
    }
  },
  destroyed() {
    document.removeEventListener('click', this.onHideCasestudyContents)
  },
  mounted() {
    document.addEventListener('click', this.onHideCasestudyContents)
    if(this.$route.query.hasOwnProperty('registration')) {
      this.$nextTick(this.openRegistrationModal);
    }
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  created() {
    if(this.$route.query.hasOwnProperty('registration') && typeof google !== 'undefined') {
      google.accounts.id.cancel();
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    onRegisterOrLogin(e) {
      if (getToken()) {
        this.closeMenu(e)
        location.href = '/workspace/'
      } else {
        this.openModalDialog();
      }
    },
    onCloseBanner() {
      this.bannerState = 'anim'
      setTimeout(() => {
        this.isAnimBanner && (this.bannerState = 'hide')
      }, this.bannerAnimTime)
    },
    onResize: debounce(function() {
      this.screenWidth = this.$el.clientWidth;
      this.setSubheaderHeight();
    }),
    setSubheaderHeight () {
      const computedStyle = getComputedStyle(this.$refs.telSubheader);
      const height = parseInt(computedStyle.height);
      this.$store.commit('nav/subheaderHeight', { height: isNaN(height) ? 0 : height });
    },
    onShowSubNav(target) {
      this.showSubNav = this.showSubNav === target ? null : target
    },
    isSubMenuBtn(e) {
      const el = e.target
      return el.classList.contains('btn-menu') || (el.closest && el.closest('.btn-menu'))
    },
    onHideCasestudyContents(e) {
      if (this.isSubMenuBtn(e)) return
      this.showSubNav = null
    },
    closeMenu(e) {
      if (this.isSubMenuBtn(e)) return
      this.showMenu = false;
    },
    onIntersect(entries) {
      this.isIntersecting = entries.isIntersecting;
    },
    openRegistrationModal() {
      if(!this.$store.state.showRegistrationModal) {
        this.onShowRegistrationModal()
        window.setTimeout(this.onShowRegistrationModal, 200);
      }
    },
  }
};
</script>
