import PhoneNumber from 'awesome-phonenumber'
import { analyticsMiddleware } from 'vue-analytics'

import api from '@/api/warex'

export const state = () => ({
  user: null,
  getStarted: false,
  accessToken: '',
  showRegistrationModal: false,
  phone: "810332102428",
  gdpr: { show: false },
  email: {
    sales: 'sales@aukai.io',
    contact: 'hello@aukai.io',
    support: 'support@aukai.io',
  },
  address: {
    en: {
      street: '2 Chome-6-１ Marunouchi',
      city: 'Chiyoda City',
      state: 'Tokyo',
      zip: '100-0005',
      country: 'Japan',
    },
    ja: {
      street: '丸の内2-6-1',
      city: '千代田区',
      state: '東京都',
      zip: '100-0005',
      country: '日本'
    }
  }
})

export const getters = {
  // we have a user in memory, but no valid access token; this should not happen often
  canUse(state, getters) {
    if (getters.isAnonymous) {
      return false;
    }
    return state.user &&
      state.accessToken &&
      (state.user.emailConfirmed || state.user.status !== 'init')
  },

  // user is anonymous, or user exists and has confirmed their email address
  canLogin(state, getters) {
    if (getters.isAnonymous) {
      return true;
    }
    return state.user &&
      (state.user.emailConfirmed || state.user.status !== 'init')
  },

  isAnonymous(state) {
    return state.user === null;
  },

  phone(state) {
    // TODO: if locale matches phone number region then return 'national' number
    return new PhoneNumber(state.phone, 'JP').getNumber('international');
  },

  phoneURI(state) {
    return new PhoneNumber(state.phone, 'JP').getNumber('rfc3966');
  },
  showRegistrationModal(state) {
    return state.showRegistrationModal
  },
}

export const mutations = {
  getStarted(state) {
    state.getStarted = !state.getStarted
  },
  hideGDPR(state) {
    state.gdpr.show = false;
  },
  showGDPR(state) {
    state.gdpr.show = true;
  },
  acceptGDPR(state) {
    state.gdpr.show = false;
    if (process.browser) {
      localStorage.setItem("GDPR:accepted2", true);
    }
  },
  rejectGDPR(state) {
    // TODO: send data directly to our server
    state.gdpr.show = false;
    if (process.browser) {
      localStorage.setItem("GDPR:accepted2", false);
    }
  },
  toggleModal(state) {
    state.showRegistrationModal = !state.showRegistrationModal;
  },
  showModal(state) {
    state.showRegistrationModal = true;
  },
  hideModal(state) {
    state.showRegistrationModal = false;
  },

  USER(state, { user, accessToken = "" }) {
    state.user = user;
    state.accessToken = accessToken;
    // FIXME: some other task is overwriting the value here on page load; probably something like vuex persist
    if ($nuxt.$route.query.registration !== null && user && (user.emailConfirmed  || user.status !== "init" )) {
      state.showRegistrationModal = false;
    }
  },
}

export const actions = {
  async nuxtServerInit({ dispatch }) {
    await dispatch('cms/global')
    await dispatch('cms/homepage')
  },

  getStarted({ commit, state }) {
    commit('getStarted', { meta: {
      analytics: [
        ['event', {
          eventCategory: 'getStarted',
          eventAction: 'toggle',
          eventLabel: !state.getStarted ? 'open' : 'close',
          eventValue: 1
        }]
      ]
    }})
  },
  acceptGDPR({ commit }, { $ga, $router }) {
    commit("acceptGDPR")
    $ga.enable();
    $ga.page($router.currentRoute.path);
  },
  rejectGDPR({ commit }) {
    commit("rejectGDPR")
  },
  showRegistrationModal({ commit }) {
    commit('showModal');
  },
  hideRegistrationModal({commit, state }) {
    const query = {}
    const _q = $nuxt.$route.query
    Object.keys(_q).forEach((key) => {
      if (key !== 'registration') {
        query[key] = _q[key]
      }
    })
    $nuxt.$router.push({ query: { ...query } })
    commit('hideModal');
  },

  async loadUser ({ commit, state }) {
    return api.getMe()
      .then(({ user, accessToken }) => {
        (user.id && user.id > 0)
          ? commit('USER', { user, accessToken })
          : commit('USER', { user: null, accessToken });
        return user;
      })
      .catch((err) => {
        // TODO: be more intelligent about this
        commit('USER', { user: null, accessToken: '' });
      });
  },

  async registerUser({ commit }, user) {
    return api.registerUser(user)
      .then((reg) => {
        commit("USER", reg);
        return reg;
      })
      .catch((error) => {
        return {
          isError: true,
          error: error,
          message: error.response.data
        };
      })
  },
  async loginUser({ commit }, user) {
    // TODO: replace with the real company/user registration API
    // return this.$axios.post(`/luau/company/`, user)
    //   .then(({ data }) => {
      const data = {isError: true};
        // Should I do something with it?
        // commit('NEW_USER', data);
        return data;
      // })
      // .catch((error) => {
      //   apiError(error, commit);
      //   return {
      //     isError: true,
      //     message: error.response.data
      //   };
      // });
  },
  async resetPassword({ commit }, { email }) {
    // TODO: replace with the real company/user registration API
    // return this.$axios.post(`/luau/company/`, email)
    //   .then(({ data }) => {
      const data = {};
        // Should I do something with it?
        // commit('NEW_USER', data);
        return data;
      // })
      // .catch((error) => {
      //   apiError(error, commit);
      //   return {
      //     isError: true,
      //     message: error.response.data
      //   };
      // });
  }
}

export const plugins = [
  analyticsMiddleware
]
