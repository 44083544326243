import Vue from 'vue';
import { url as cmsURL, metaTags as cmsMetaTags } from '../utils/cms';

export default async ({ $strapi }, inject) => {

  Vue.filter("cmsURL", cmsURL($strapi));
  Vue.filter("cmsMetaTags", cmsMetaTags($strapi));
  inject("cms", {
    url: cmsURL($strapi),
    metaTags: cmsMetaTags($strapi)
  });
}