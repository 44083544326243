import MarkdownIt from 'markdown-it'

const handlePlugin = (plugin) => plugin.default || plugin

export default ({}, inject) => {
  const md = new MarkdownIt('default', {"linkify":true,"breaks":true,"injected":true})

  md.use(handlePlugin(require('markdown-it-iframe')), {"width":560,"height":315} )

  md.use(handlePlugin(require('markdown-it-video')))

  md.use(handlePlugin(require('markdown-it-span')))

  md.use(handlePlugin(require('markdown-it-regex')), {"name":"allowTags","regex":new RegExp("(\u003Cu\u003E|\u003C\\\u002Fu\u003E|\u003Cstrong\u003E|\u003C\\\u002Fstrong\u003E)", ""),"replace":function(match) {
        return match;
      }} )

  md.use(handlePlugin(require('~/plugins/markdownit-rules')))

  inject('md', md)
}