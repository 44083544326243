export default async function ( { app, route, store, error }) {
  const feature = route.meta.reduce((f, meta) => {
    // TODO: compare the values and choose the most appopriate one
    return meta.feature || f;
  }, '');

  if (!feature) {
    return;
  }

  if (!store.getters["featureToggle/isLoaded"]) {
    // This can cause a double load if the plugin is already executing the call
    await store.dispatch("featureToggle/load")
      .then(() => (
        store.dispatch("featureToggle/loadFromParams", params)
          .then(() => store.dispatch("featureToggle/loadFromParams", query))
      ))
      .catch(err => console.error("error getting feature toggles from toggle service", err));
  }
  if (store.getters["featureToggle/isOff"](feature)) {
    // TODO: send an error report
    return error({ statusCode: 404, message: `${feature} is not available` });
  }
}