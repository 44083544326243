// TODO: DRY with Kolu and Clark


import urlJoin from 'url-join';
import reqURL from 'requrl';
import axios from 'axios';
import applyConverters from 'axios-case-converter';
import addressModel from '../../model/address';

export const http = applyConverters(axios.create());

// baseURL needs to be executed at runtime and the result cannot be assigned to
// a variable, otherwise it can end up embedded in the generated site content.
// We need the `req` to be passed, when available, so that we can infer
// the absolute URL of the server when running in dev mode. When we run in
// generate mode there will be no `req` and no `window`, so we rely on config
// being properly set, i.e., the VUE_APP_API_URL.
export const baseURL = ((req) => {
  if (req) {
    return reqURL(req)
  }

  if (typeof window !== "undefined") {
    return window.location.origin;
  }

  if (process.env.VUE_APP_API_URL) {
    return process.env.VUE_APP_API_URL;
  }

  return '/';
});

RegExp.escape = function (string) {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const url = (...parts) => {
  return urlJoin(baseURL(), 'api', 'v2', ...parts);
};

export const normalizeDates = (obj) => {
  ['createdAt', 'updatedAt', 'deletedAt'].forEach((key) => {
    if (typeof obj[key] === 'number') {
      obj[key] = new Date(obj[key]);
    } else if (typeof obj[key] === 'string') {
      obj[key] = new Date(Date.parse(obj[key]));
    }
  });
  return obj;
};

export const deNormalizeDates = (obj) => {
  ['createdAt', 'updatedAt', 'deletedAt'].forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      if (obj[key].constructor === Date) {
        obj[key] = obj[key].getTime();
      } else if (typeof obj[key] === 'string') {
        obj[key] = Date.parse(obj[key]);
      }
    }
  });
  return obj;
};

export const normalize = (obj) => {
  return normalizeDates(obj);
};

export const deNormalize = (obj) => {
  return deNormalizeDates(obj);
};

const addressAttrs = Object.keys(addressModel);

export const structureAddressObj = (obj) => {
  if (!obj.address) {
    obj.address = Object.assign({}, addressModel);
  }
  addressAttrs.forEach((key) => {
    obj.address[key] = obj[key];
  });
  return obj;
};

export const destructureAddressObj = (obj) => {
  if (obj.address) {
    addressAttrs.forEach((key) => {
      obj[key] = obj.address[key];
    });
  }
  return obj;
};

export default {
  http,
  url,
  normalize,
  deNormalize,
  structureAddressObj,
  destructureAddressObj,
  baseURL,
};
