<i18n>
{
  "ja": {
    "404": "お探しのページは見つかりませんでした。",
    "error": "エラーが発生しました",
    "bth": "トップページへ戻る"
  },
  "en": {
    "404": "This page could not found.",
    "error": "An error occured",
    "bth": "Back to the home page"
  }
}
</i18n>

<template>
  <div class="page_error">
    <div class="p-16">
      <h1 v-if="error.statusCode === 404" class="text-xl text-center">{{$t('404')}}</h1>
      <h1 v-else class="text-xl text-center">{{$t('error')}}</h1>
      <div class="mt-12">
        <img class="mx-auto" src="~/assets/img/illust-404.png" alt="" srcset="~/assets/img/illust-404@2x.png 2x, ~/assets/img/illust-404@3x.png 3x">
      </div>
      <div class="mt-8 text-center">
        <nuxt-link class="border rounded-full text-sm font-medium text-center leading-none p-4 px-8 inline-block" :to="localePath('/')">{{$t('bth')}}</nuxt-link>
      </div>
    </div>
  </div>
</template>
<style lang="sass" scoped>
.page_error
  height: 30vw
  min-height: 62.5vh
  @apply flex flex-col justify-center items-center
</style>
<script>
export default {
  props: ['error'],
  head() {
    return {
      title: this.error.statusCode === 404 ? this.$t('404') : this.$t('error'),
      meta: [
        { hid: "robots", name: "robots", content: "noindex, nofollow" }
      ]
    }
  }
}
</script>
